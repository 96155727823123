import React, { useEffect, useState } from 'react';
import { ClientMetricsData, useLazyGetMetadataQuery, useLazyGetMetricByIdQuery } from '../../../services/metricsApi';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { clusterData } from './utils';
import { ResultsPanel } from './ResultsPanel';
import { FormattedDateTime } from '../../common/FormattedDateTime';

const ENTER_KEY = 'Enter';

type Metadata = {
    start: string;
    end: string;
    results: number;
};

export const ClientMetricsPage = () => {
    const [searchValue, setSearchValue] = useState('');
    const [searchDisabled, setSearchDisabled] = useState(false);
    const [searchChanged, setSearchChanged] = useState(false);
    const [data, setData] = useState<{
        success: ClientMetricsData[];
        failure: ClientMetricsData[];
        other: ClientMetricsData[];
    }>({ success: [], failure: [], other: [] });
    const [metadata, setMetadata] = useState<Metadata>({ start: '', end: '', results: 0 });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchChanged(true);
        setSearchValue(event.currentTarget.value);
    };

    const [fetchMetrics, fetchMetricsResults] = useLazyGetMetricByIdQuery();
    const [fetchMetadata, fetchMetadataResults] = useLazyGetMetadataQuery();

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === ENTER_KEY && searchChanged && searchValue) {
            setSearchDisabled(true);
            setSearchChanged(false);

            fetchMetrics({ id: searchValue });
            fetchMetadata();
        }
    };

    useEffect(() => {
        if (fetchMetricsResults.isFetching === false) {
            setSearchDisabled(false);
        }
    }, [fetchMetricsResults.isFetching]);

    useEffect(() => {
        if (fetchMetricsResults.isError) {
            Notification.warning('No data found.');
        }
    }, [fetchMetricsResults.isError]);
    useEffect(() => {
        if (fetchMetadataResults.isError) {
            Notification.warning('No data found.');
        }
    }, [fetchMetadataResults.isError]);

    useEffect(() => {
        if (fetchMetricsResults.data) {
            const { success, failure, other } = clusterData(fetchMetricsResults.data);
            setData({ success, failure, other });
        }
    }, [fetchMetricsResults.data, setData]);

    useEffect(() => {
        if (fetchMetadataResults.data) {
            const { start, end, results } = fetchMetadataResults.data;
            setMetadata({ start, end, results });
        }
    }, [fetchMetadataResults.data, setMetadata]);

    return (
        <div className="container" data-cy="client-metrics-page">
            <SearchBarWrapper>
                <div className={'input-group input-group-lg'}>
                    <span className={'input-group-addon'}>
                        <span className={'rioglyph rioglyph-search text-size-20'} />
                    </span>
                    <input
                        value={searchValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        className={'form-control'}
                        placeholder={'Search for client or partner-integration by ID'}
                        readOnly={searchDisabled}
                        disabled={searchDisabled}
                    />
                </div>
            </SearchBarWrapper>
            {!fetchMetricsResults.isError && fetchMetricsResults.originalArgs && (
                <div>
                    <span>Data for ID: </span>
                    <span className={'text-bold'}>{fetchMetricsResults.originalArgs['id']}</span>
                </div>
            )}
            {!fetchMetricsResults.isError && fetchMetricsResults.originalArgs && !fetchMetadataResults.isError && (
                <div>
                    <span>Based on Last Execution: </span>
                    <FormattedDateTime value={metadata.start} />
                </div>
            )}
            {!fetchMetricsResults.isError && fetchMetricsResults.data && (
                <div className={'margin-top-10'}>
                    {!!data.success.length && <ResultsPanel data={data.success} panelType="success" />}
                    {!!data.failure.length && <ResultsPanel data={data.failure} panelType="danger" />}
                    {!!data.other.length && <ResultsPanel data={data.other} panelType="warning" />}
                </div>
            )}
        </div>
    );
};

const SearchBarWrapper = (props: React.PropsWithChildren) => (
    <div className={'row margin-bottom-25'}>
        <div className={'col-xs-0 col-md-2 col-xl-4'} />
        <div className={'col-xs-12 col-md-8 col-xl-4'}>
            <div className={'position-relative'}>{props.children}</div>
        </div>
    </div>
);
