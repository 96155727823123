import { Account } from '../../../../../services/schemas/accountsBackendCodec';
import { ChangeEvent, useState } from 'react';
import { FormInputField } from '../../FormInputField';
import { FormattedMessage } from 'react-intl';
import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { CompactTable } from '../../sidebar/CompactTable';

interface EditAccountConfirmationProps {
    account: Account;
    oldAccount: Account;
    updateDialogIsLoading: boolean;
    handleUpdateConfirm: (reason: string) => void;
    dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<Account>>>;
    cancelUpdate: () => void;
}

const isReasonValid = (reason: string | undefined): boolean => (reason ? reason.trim().length > 0 : false);
const isConfirmationTextValid = (confirmationText: string) => 'UPDATE' === confirmationText;

const isFormValid = (reason: string | undefined, confirmationText: string) =>
    reason ? isReasonValid(reason) && isConfirmationTextValid(confirmationText) : false;

export const EditAccountConfirmation = ({
    account,
    oldAccount,
    dirtyFields,
    updateDialogIsLoading,
    handleUpdateConfirm,
    cancelUpdate,
}: EditAccountConfirmationProps) => {
    const [confirmationText, setConfirmationText] = useState('');
    const [reason, setReason] = useState<string | undefined>(undefined);

    const handleReasonChange = (_propertyName: string, value: string) => setReason(value);

    const handleConfirmationTextChange = (event: ChangeEvent<HTMLInputElement>) =>
        setConfirmationText(event.target.value);

    const getModalContent = (account: Account) => (
        <div data-cy={'modal-content-update'}>
            <div>
                <FormInputField
                    dataAttribute="update-account-dialog-reason-input"
                    translationId={'helpgang.addFleetAdminDialog.reason'}
                    value={reason}
                    propertyName={'reason'}
                    formError={reason?.length === 0 ? 'helpgang.deleteUserDialog.reason.error' : undefined}
                    required={true}
                    onValueChange={handleReasonChange}
                />
            </div>
            <p>
                <FormattedMessage
                    id={'helpgang.updateAccountDialog.areYouSure'}
                    values={{
                        b: (chunks) => <span className={'text-bold text-color-warning'}>{chunks}</span>,
                    }}
                />
            </p>
            <div className={'table-responsive'}>
                <CompactTable account={account} dirtyFields={dirtyFields} oldAccount={oldAccount} />
            </div>
            <p>
                <FormattedMessage id={'helpgang.modal.typeConfirmation'} />:{' '}
                <span className="text-bold text-color-danger pointer-events-none user-select-none">UPDATE</span>
            </p>
            <div className="form-group">
                <input
                    className="form-control"
                    type="text"
                    value={confirmationText}
                    onChange={handleConfirmationTextChange}
                    data-cy={'update-account-dialog-confirmation-input'}
                />
            </div>

            <div className="margin-top-25 margin-bottom-15 display-flex justify-content-between">
                <button className={'btn btn-default'} onClick={cancelUpdate}>
                    Back
                </button>
                <button
                    className={'btn btn-primary'}
                    disabled={!isFormValid(reason, confirmationText) && !updateDialogIsLoading}
                    onClick={() => handleUpdateConfirm(reason!)}
                >
                    Confirm
                </button>
            </div>
        </div>
    );

    return getModalContent(account);
};
