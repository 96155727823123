import { FormattedMessage } from 'react-intl';
import { KeyboardEvent } from 'react';
import { IdentifierSelectComponent } from './IdentifierSelectComponent';
import { SelectOption } from '@rio-cloud/rio-uikit/Select';

const CLIENT_ID = 'client_id';
const ACCOUNT_ID = 'account_id';
const INTEGRATION_ID = 'integration_id';
export const searchIdentifier = {
    CLIENT_ID,
    ACCOUNT_ID,
    INTEGRATION_ID,
};

export const identifierOptions: SelectOption[] = [
    {
        id: searchIdentifier.CLIENT_ID,
        label: 'Client Id',
    },
    {
        id: searchIdentifier.ACCOUNT_ID,
        label: 'Account Id',
    },
    {
        id: searchIdentifier.INTEGRATION_ID,
        label: 'Integration Id',
    },
];

interface SearchHeaderProps {
    handleSearch: () => void;
    handleIdentifierSelection: (item: SelectOption | undefined) => void;
    pending: boolean;
    searchString: string;
    setSearchString: (searchString: string) => void;
    searchTypeValue: string;
}

export const SearchHeader = (props: SearchHeaderProps) => {
    const { handleSearch, handleIdentifierSelection, searchTypeValue, pending, searchString, setSearchString } = props;

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className={'row margin-top-15'}>
            <div className={'col-xs-3'} />
            <div className={'col-xs-5'}>
                <div className={'form-group'}>
                    <div className={'input-group'}>
                        <div className={'input-group-btn identifier-select'}>
                            <IdentifierSelectComponent
                                identifierOptions={identifierOptions}
                                searchTypeValue={searchTypeValue}
                                changeIdentifierSelection={(selectOption: SelectOption | undefined) => {
                                    handleIdentifierSelection(selectOption);
                                }}
                            />
                        </div>
                        <input
                            id={'partnerIntegrationSearchIdentifierInput'}
                            type={'text'}
                            className={'form-control input-lg'}
                            placeholder={''}
                            disabled={pending}
                            value={searchString}
                            onChange={(input) => {
                                setSearchString(input.target.value);
                            }}
                            onKeyDown={handleKeyDown}
                            data-cy={'input-partner-integration-search'}
                        />
                    </div>
                </div>
            </div>
            <div className="col-xs-1">
                {!pending && (
                    <button
                        className={'btn btn-primary btn-lg'}
                        id={'searchButton'}
                        type={'button'}
                        onClick={handleSearch}
                        disabled={searchString === ''}
                    >
                        <FormattedMessage id={'helpgang.search.btnText'} />
                    </button>
                )}
                {pending && (
                    <button className={'btn btn-primary btn-loading'} disabled>
                        loading
                    </button>
                )}
            </div>
        </div>
    );
};
