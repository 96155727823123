import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import React, { ReactNode, useEffect, useState } from 'react';
import { AccountRest, mapToAccount } from '../../../services/schemas/accountsBackendCodec';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomSearch } from './CustomSearch';
import { accountSelected, resetAccountsSidebar, setShowAccountSidebar } from './sidebar/accountSidebarSlice';
import { getSelectedAccountId, setSelectedAccountId } from './accountsSlice';
import { AccountStatus } from './AccountStatus';
import { config } from '../../../../config';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import { useLazyGetAccountsForSearchParamQuery } from '../../../services/accountsApi';
import { ValueWithCopyButton } from '../../common/ValueWithCopyButton';
import { AppDispatch } from '../../../../configuration/setup/store';

const tableClassNames =
    'table table-layout-fixed table-column-overflow-hidden table-bordered table-sticky table-head-filled';

const ENTER_KEY = 'Enter';
const MAX_ACCOUNTS_TO_DISPLAY = config.maxAccountsToDisplay;

interface AccountsTableProps {
    searchValue: string;
    setSearchValue: (val: string) => void;
}

export const AccountsTable = (props: AccountsTableProps) => {
    const { searchValue, setSearchValue } = props;

    const [selectedRowId, setSelectedRowId] = useState<string | undefined>(undefined);
    const [searchDisabled, setSearchDisabled] = useState(false);

    const [selectedAccount, setSelectedAccount] = useState<AccountRest | undefined>(undefined);
    const [searchChanged, setSearchChanged] = useState(false);

    const intl = useIntl();
    const dispatch = useAppDispatch();

    const selectedAccountIdFromStore = useAppSelector(getSelectedAccountId);

    const [fetchAccountsRtk, { data: accountsNew, isFetching }] = useLazyGetAccountsForSearchParamQuery();

    const handleClickOnRow = (account: AccountRest) => {
        if (selectedAccount && selectedAccount.id === account.id) {
            setSelectedAccount(undefined);
            dispatch(setSelectedAccountId(undefined));
        } else {
            dispatch(setSelectedAccountId(account.id));
            setSelectedAccount(account);
        }
    };

    useEffect(() => {
        if (selectedAccount) {
            openSidebarForAccount(selectedAccount, dispatch);
            setSelectedRowId(selectedAccount.id);
        } else {
            dispatch(resetAccountsSidebar());
            setSelectedRowId(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount, dispatch]);

    useEffect(() => {
        if (!isFetching) {
            setSearchDisabled(false);
        }
    }, [isFetching, setSearchDisabled]);

    useEffect(() => {
        if (selectedAccountIdFromStore === undefined) {
            setSelectedAccount(undefined);
            setSelectedRowId(undefined);
        }
    }, [selectedAccountIdFromStore]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchChanged(true);
        setSearchValue(event.currentTarget.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === ENTER_KEY && searchChanged) {
            dispatch(setSelectedAccountId(undefined));
            dispatch(resetAccountsSidebar());

            setSearchDisabled(true);
            setSearchChanged(false);

            fetchAccountsRtk({ search: searchValue });
        }
    };

    const renderTableContent = (): ReactNode => {
        if (isFetching) {
            return (
                <div>
                    <Spinner isDoubleSized={true} />
                </div>
            );
        } else if (!isFetching && accountsNew && accountsNew.length > 0) {
            return (
                <table className={tableClassNames}>
                    <thead>
                        <tr>
                            <th>Account Name</th>
                            <th>Account Id</th>
                            <th>Account Tenant</th>
                            <th>Type</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {accountsNew.map((rowItem, index) => (
                            <tr
                                data-cy="accounts-table-row"
                                key={index}
                                data-key={rowItem.id}
                                className={rowItem.id === selectedRowId ? 'active' : ''}
                                onClick={() => handleClickOnRow(rowItem)}
                            >
                                <td>{rowItem.name}</td>
                                <td>
                                    <ValueWithCopyButton value={rowItem.id} />
                                </td>
                                <td>{rowItem.tenant}</td>
                                <td>{rowItem.account_type}</td>
                                <td>
                                    <AccountStatus status={rowItem.life_cycle_state} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        } else if (!isFetching && accountsNew == undefined) {
            return null;
        } else {
            return (
                <NotFoundState
                    headline={intl.formatMessage({ id: 'helpgang.search.nothingFound' })}
                    message={intl.formatMessage({ id: 'helpgang.search.refineSearch' })}
                />
            );
        }
    };

    return (
        <div id={'Accounts'}>
            <SearchBarWrapper>
                <CustomSearch
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    handleInputChange={handleInputChange}
                    handleKeyDown={handleKeyDown}
                    isSearchDisabled={searchDisabled}
                />
                {accountsNew && accountsNew.length >= MAX_ACCOUNTS_TO_DISPLAY && <TooManyAccountsWarning />}
            </SearchBarWrapper>
            {renderTableContent()}
        </div>
    );
};

const SearchBarWrapper = (props: React.PropsWithChildren) => (
    <div className={'row margin-bottom-25'}>
        <div className={'col-xs-0 col-md-2 col-xl-4'} />
        <div className={'col-xs-12 col-md-8 col-xl-4'}>
            <div className={'position-relative'}>{props.children}</div>
        </div>
    </div>
);
const TooManyAccountsWarning = () => (
    <div
        data-cy={'too-many-results-banner'}
        className={'position-absolute left-0 right-0 top-100pct padding-10 bg-white shadow-smooth rounded z-index-10'}
    >
        <FormattedMessage id={'helpgang.search.tooManyResults'} values={{ MAX_ACCOUNTS: MAX_ACCOUNTS_TO_DISPLAY }} />
    </div>
);

const openSidebarForAccount = (accountRest: AccountRest, dispatch: AppDispatch) => {
    dispatch(accountSelected(mapToAccount(accountRest)));
    dispatch(setShowAccountSidebar(true));
};
