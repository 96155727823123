import { Account } from '../../../../../services/schemas/accountsBackendCodec';
import React, { useState } from 'react';
import { DeleteUserDialog } from '../../dialogs/DeleteUserDialog';

export const DeleteUserInAccount = ({ account }: { account: Account }) => {
    const [showDialog, setShowDialog] = useState(false);

    const handleClick = (evt: React.MouseEvent<HTMLElement>) => {
        evt.stopPropagation();
        setShowDialog(true);
    };

    return (
        <>
            <button className={'btn btn-primary'} onClick={handleClick}>
                Delete User
            </button>
            <DeleteUserDialog accountId={account.id} showDialog={showDialog} setShowDialog={setShowDialog} />
        </>
    );
};
