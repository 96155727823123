import { ClientMetricsData } from '../../../services/metricsApi';
import { FormattedDateTime } from '../../common/FormattedDateTime';

export const ResultsPanel = ({
    data,
    panelType,
}: {
    data: ClientMetricsData[];
    panelType: 'success' | 'danger' | 'warning';
}) => {
    let title: string;
    switch (panelType) {
        case 'success':
            title = 'Successful authentication requests';
            break;
        case 'danger':
            title = 'Failed authentication requests';
            break;
        case 'warning':
            title = 'Other';
    }
    return (
        <div className={'row margin-bottom-20'}>
            <div className={'col-xs-4'}>
                <div className={`panel panel-${panelType}`}>
                    <div className="panel-heading">{title}</div>
                    <div className="panel-body">
                        {data.map((it, idx) => (
                            <DataPanel data={it} key={idx} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const DataPanel = ({ data }: { data: ClientMetricsData }) => (
    <div className="panel panel-default">
        <div className="panel-body">
            <div>
                <span>Status code: </span>
                <span>{data.statusCode}</span>
            </div>
            <div>
                <span>Last occurrence (before last execution): </span>
                <FormattedDateTime value={data.lastSeen} />
            </div>
            <div>
                <span>Number of occurrences (last week): </span>
                <span>{data.amount}</span>
            </div>
        </div>
    </div>
);
