import { LogLevel } from 'configcat-react';

export interface ConfigState {
    backend: {
        AUTHENTICATION_SERVICE: string | undefined;
        ACCOUNTS_SERVICE: string | undefined;
        USERADMIN_SERVICE: string | undefined;
        PARTNER_INTEGRATION_SERVICE: string | undefined;
        CLIENT_REGISTRY: string | undefined;
        CLIENT_METRICS: string | undefined;
        INBOUND_CONNECTIONS_URL: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: Array<string>;
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    logoutUri: string | undefined;
    configCat: {
        apiKey: string;
        logLevel: LogLevel;
        pollingFrequencyInSeconds: number;
    };
    maxAccountsToDisplay: number;
}

const asBool = (value: string | undefined): boolean => value === 'true';

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        ACCOUNTS_SERVICE: import.meta.env.VITE_ACCOUNTS_SERVICE,
        USERADMIN_SERVICE: import.meta.env.VITE_USERADMIN_SERVICE,
        PARTNER_INTEGRATION_SERVICE: import.meta.env.VITE_PARTNER_INTEGRATION_SERVICE,
        CLIENT_REGISTRY: import.meta.env.VITE_CLIENT_REGISTRY,
        CLIENT_METRICS: import.meta.env.VITE_CLIENT_METRICS,
        INBOUND_CONNECTIONS_URL: import.meta.env.VITE_INBOUND_CONNECTIONS_URL,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: '8e3386eb-0139-41f2-9eda-31ef547958b9',
        oauthScope: ['openid', 'profile', 'email', 'accounts.read', 'accounts.write'],
        mockAuthorization: import.meta.env.DEV,
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    configCat: {
        apiKey: '',
        logLevel: import.meta.env.VITE_CONFIG_CAT_LOG_LEVEL === 'INFO' ? LogLevel.Info : LogLevel.Off,
        pollingFrequencyInSeconds: 600,
    },
    maxAccountsToDisplay: parseInt(import.meta.env.VITE_MAX_ACCOUNTS_TO_DISPLAY ?? '30'),
};
