import { ClientsTable } from './ClientsTable';

export const Clients = () => (
    <>
        <div className={'row margin-top-25'}>
            <div className={'col-xs-12'}>
                <h1>Get partner integration clients</h1>
            </div>
        </div>
        <div className={'row'}>
            <div className={'col-xs-12'}>
                <div className={'panel panel-default'}>
                    <div className={'panel-body'}>
                        <ClientsTable />
                    </div>
                </div>
            </div>
        </div>
    </>
);
