import { useSelector } from 'react-redux';
import { ValueWithCopyButton } from '../../../common/ValueWithCopyButton';
import { RootState } from '../../../../../configuration/setup/store.ts';

export const CreatedIntegrationsTable = () => {
    const createdIntegrations = useSelector((state: RootState) => state.partnerIntegration.createdIntegrations);

    return (
        <table className="table table-hover">
            <thead>
                <tr>
                    <th>Time</th>
                    <th>Integration-ID</th>
                    <th>Client-ID</th>
                    <th>Account</th>
                </tr>
            </thead>
            <tbody>
                {createdIntegrations.map((integration, index) => (
                    <tr key={index} data-cy={'created-partner-integrations-table-row'}>
                        <td>{integration.timestamp}</td>
                        <td>
                            <ValueWithCopyButton value={integration.integrationId} fieldName={'Integration-ID'} />
                        </td>
                        <td>{integration.clientId}</td>
                        <td>
                            {integration.accountName} ({integration.accountId})
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
