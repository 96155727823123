import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { ChangeEvent } from 'react';

export interface FormInputFieldProps {
    translationId: string;
    value?: string;
    maxLength?: number;
    propertyName?: string;
    placeholder?: string;
    formError?: string | undefined;
    hasError?: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
    required?: boolean;
    dataAttribute?: string;
    onValueChange?: (propertyName: string, changedValue: string) => void;
    id?: string;
}

export const FormInputField = (props: FormInputFieldProps) => {
    const {
        translationId,
        value,
        maxLength,
        propertyName,
        formError,
        disabled,
        autoFocus,
        required,
        dataAttribute,
        onValueChange,
        id,
        placeholder,
    } = props;

    const intl = useIntl();

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        return onValueChange && propertyName ? onValueChange(propertyName, event.target.value) : () => {};
    };

    return (
        <div className={classNames('form-group', formError && 'has-feedback has-error')}>
            <label htmlFor={id}>
                <FormattedMessage id={translationId} /> {required && '*'}
            </label>
            <div className="input-group">
                <input
                    className="form-control"
                    type="text"
                    readOnly={disabled ? disabled : false}
                    value={value ? value : ''}
                    placeholder={placeholder ? placeholder : intl.formatMessage({ id: translationId })}
                    onChange={onChange}
                    maxLength={maxLength ? maxLength : -1}
                    data-cy={dataAttribute && dataAttribute}
                    autoFocus={autoFocus ? autoFocus : false}
                    id={id}
                />
            </div>
            {formError && (
                <span className={'help-block'}>
                    <FormattedMessage id={formError} />
                </span>
            )}
        </div>
    );
};
