import { useGetAllClientsByGrantTypeQuery } from '../../services/clientRegistryApi';

export const ClientIdWithName = ({ clientId }: { clientId: string }) => {
    const { data } = useGetAllClientsByGrantTypeQuery('partner_integration');
    const filteredData = data?.filter((item) => item.id === clientId)?.map((item) => item.name);
    return filteredData && filteredData.length === 1 ? (
        <table className={'table table-condensed text-color-black'}>
            <tbody>
                <tr>
                    <td className={'padding-0 padding-bottom-5'} style={{ borderTop: 0 }}>
                        {clientId}
                    </td>
                </tr>
                <tr>
                    <td className={'padding-0 text-color-dark'} style={{ borderTop: 0 }}>
                        {filteredData[0]}
                    </td>
                </tr>
            </tbody>
        </table>
    ) : (
        <span>{clientId}</span>
    );
};
