import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { AccountStatus } from '../AccountStatus';
import { Account } from '../../../../services/schemas/accountsBackendCodec';

export const CompactTable = ({
    account,
    dirtyFields,
    oldAccount,
}: {
    account: Account;
    dirtyFields?: Partial<Readonly<FieldNamesMarkedBoolean<Account>>>;
    oldAccount?: Account;
}) => {
    return (
        <table className={'table table-head-filled table-condensed'}>
            <thead>
                <tr>
                    <th>Properties</th>
                    {oldAccount ? <th className={'text-color-gray'}>Account before edit</th> : ''}
                    <th>Account after edit</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Account Id</td>
                    {oldAccount ? <td className={'text-color-gray'}>{oldAccount.id}</td> : ''}
                    <td>{account.id}</td>
                </tr>
                <tr className={dirtyFields?.name ? 'text-bold text-color-warning' : ''}>
                    <td>Account Name</td>
                    {oldAccount ? <td className={'text-color-gray'}>{oldAccount.name}</td> : ''}
                    <td>{account.name}</td>
                </tr>
                <tr>
                    <td>Account Tenant</td>
                    {oldAccount ? <td className={'text-color-gray'}>{oldAccount.tenant}</td> : ''}
                    <td>{account.tenant}</td>
                </tr>
                <tr>
                    <td>Account Type</td>
                    {oldAccount ? <td className={'text-color-gray'}>{oldAccount.accountType}</td> : ''}
                    <td>{account.accountType}</td>
                </tr>
                <tr>
                    <td>Registration Channel</td>
                    {oldAccount ? <td className={'text-color-gray'}>{oldAccount.registrationChannel}</td> : ''}
                    <td>{account.registrationChannel}</td>
                </tr>
                <tr className={dirtyFields?.taxId?.value ? 'text-bold text-color-warning' : ''}>
                    <td>Tax Id</td>
                    {oldAccount ? <td className={'text-color-gray'}>{oldAccount.taxId?.value}</td> : ''}
                    <td>{account.taxId?.value}</td>
                </tr>
                <tr className={dirtyFields?.taxId?.taxType ? 'text-bold text-color-warning' : ''}>
                    <td>Type of Tax Id</td>
                    {oldAccount ? <td className={'text-color-gray'}>{oldAccount.taxId?.taxType}</td> : ''}
                    <td>{account.taxId?.taxType}</td>
                </tr>
                <tr className={dirtyFields?.dunsNumbers ? 'text-bold text-color-warning' : ''}>
                    <td>DUNS</td>
                    {oldAccount ? (
                        <td className={'text-color-gray'}>
                            <ul className="icon-list margin-bottom-0">
                                {oldAccount.dunsNumbers?.map((it) => (
                                    <li key={it} className={'margin-bottom-0'}>
                                        {it}
                                    </li>
                                ))}
                            </ul>
                        </td>
                    ) : (
                        ''
                    )}
                    <td>
                        <ul className="icon-list margin-bottom-0">
                            {account.dunsNumbers?.map((it) => (
                                <li key={it} className={'margin-bottom-0'}>
                                    {it}
                                </li>
                            ))}
                        </ul>
                    </td>
                </tr>
                <tr className={dirtyFields?.legalAddress?.line1 ? 'text-bold text-color-warning' : ''}>
                    <td>Address Line 1 (usually street & no.)</td>
                    {oldAccount ? <td className={'text-color-gray'}>{oldAccount.legalAddress.line1}</td> : ''}
                    <td>{account.legalAddress.line1}</td>
                </tr>
                <tr>
                    <td>Address Line 2 (usually unused)</td>
                    {oldAccount ? <td className={'text-color-gray'}>{oldAccount.legalAddress.line2}</td> : ''}
                    <td>{account.legalAddress.line2}</td>
                </tr>
                <tr>
                    <td>Address Line 3 (usually unused)</td>
                    {oldAccount ? <td className={'text-color-gray'}>{oldAccount.legalAddress.line3}</td> : ''}
                    <td>{account.legalAddress.line3}</td>
                </tr>
                <tr className={dirtyFields?.legalAddress?.city ? 'text-bold text-color-warning' : ''}>
                    <td>Town/City</td>
                    {oldAccount ? <td className={'text-color-gray'}>{oldAccount.legalAddress.city}</td> : ''}
                    <td>{account.legalAddress.city}</td>
                </tr>
                <tr className={dirtyFields?.legalAddress?.postCode ? 'text-bold text-color-warning' : ''}>
                    <td>Post Code</td>
                    {oldAccount ? <td className={'text-color-gray'}>{oldAccount.legalAddress.postCode}</td> : ''}
                    <td>{account.legalAddress.postCode}</td>
                </tr>
                <tr className={dirtyFields?.legalAddress?.countryCode ? 'text-bold text-color-warning' : ''}>
                    <td>Country Code</td>
                    {oldAccount ? <td className={'text-color-gray'}>{oldAccount.legalAddress.countryCode}</td> : ''}
                    <td>{account.legalAddress.countryCode}</td>
                </tr>
                <tr>
                    <td>Lifecycle State</td>
                    {oldAccount ? <td className={'text-color-gray'}>{oldAccount.lifeCycleState}</td> : ''}
                    <td>{dirtyFields ? account.lifeCycleState : <AccountStatus status={account.lifeCycleState} />}</td>
                </tr>
            </tbody>
        </table>
    );
};
