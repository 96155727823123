import { setAccountId, setClientId } from '../partnerIntegrationSlice';
import { ChangeEvent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';
import { useCreatePartnerIntegrationMutation } from '../../../../services/partnerIntegrationApi';
import { useLazyGetAccountNameByIdQuery } from '../../../../services/accountsApi';
import { RootState } from '../../../../../configuration/setup/store';

export const CreatePartnerIntegration = () => {
    const { accountId, clientId, status } = useAppSelector((state: RootState) => ({
        accountId: state.partnerIntegration.accountId,
        clientId: state.partnerIntegration.clientId,
        accountName: state.partnerIntegration.accountName,
        status: state.partnerIntegration.status,
    }));

    const [accountIdModified, setAccountIdModified] = useState(false);

    const [createPartnerIntegration] = useCreatePartnerIntegrationMutation();
    const [fAccountName] = useLazyGetAccountNameByIdQuery();

    const dispatch = useAppDispatch();

    const canCreate = status !== 'pending' && accountId && clientId;

    const onChangeAccountId = (evt: ChangeEvent<HTMLInputElement>) => {
        setAccountIdModified(true);
        dispatch(setAccountId(evt.currentTarget.value));
    };

    const onChangeClientId = (evt: ChangeEvent<HTMLInputElement>) => dispatch(setClientId(evt.currentTarget.value));

    const onLeaveAccountId = () => {
        if (accountIdModified && accountId) {
            fAccountName(accountId);
            // dispatch(fetchAccountName());
            setAccountIdModified(false);
        }
    };

    return (
        <>
            <fieldset>
                <div className="form-group">
                    <label htmlFor="accountID" className="control-label">
                        Account-ID
                    </label>
                    <input
                        value={accountId}
                        onFocus={() => setAccountIdModified(false)}
                        onBlur={onLeaveAccountId}
                        onChange={onChangeAccountId}
                        type="text"
                        className="form-control"
                        id="accountID"
                        placeholder="Account-ID"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="clientID" className="control-label">
                        Client-ID
                    </label>
                    <input
                        value={clientId}
                        onChange={onChangeClientId}
                        type="text"
                        className="form-control"
                        id="clientID"
                        placeholder="Client-ID"
                    />
                </div>
                <div className="btn-toolbar margin-top-20">
                    {status === 'pending' ? (
                        <button className="btn btn-primary btn-loading-overlay disabled">Create</button>
                    ) : (
                        <button
                            onClick={() => createPartnerIntegration({ accountId, clientId })}
                            className={`btn btn-primary ${canCreate ? '' : 'disabled'}`}
                        >
                            Create
                        </button>
                    )}
                </div>
            </fieldset>
        </>
    );
};
