import { useState } from 'react';
import { AccountsTable } from './AccountsTable';

export const AccountsPage = () => {
    const [searchValue, setSearchValue] = useState('');

    return (
        <div className={'padding-25'}>
            <AccountsTable searchValue={searchValue} setSearchValue={setSearchValue} />
        </div>
    );
};
