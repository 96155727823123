import { Controller, useFormContext } from 'react-hook-form';
import ClearableInput from '@rio-cloud/rio-uikit/ClearableInput';
import { Account } from '../../../../services/schemas/accountsBackendCodec';

const SimpleTextFormElement = (props: {
    description?: string;
    name:
        | 'id'
        | 'name'
        | 'tenant'
        | 'registrationChannel'
        | 'taxId.value'
        | 'taxId.taxType'
        | 'legalAddress.line1'
        | 'legalAddress.line2'
        | 'legalAddress.line3'
        | 'legalAddress.city'
        | 'legalAddress.postCode'
        | 'legalAddress.countryCode'
        | 'lifeCycleState';
    rules: any;
    isEditable: boolean;
}) => {
    const { control } = useFormContext<Account>(); // retrieve all hook methods
    return (
        <Controller
            name={props.name}
            control={control}
            rules={props.rules}
            render={({ field, fieldState }) => (
                <div className={`form-group ${fieldState.error ? 'has-feedback has-error' : ''}`}>
                    <label className="control-label" htmlFor={props.name}>
                        {props.description}
                    </label>
                    {props.isEditable ? (
                        <ClearableInput {...field} id={props.name} data-testid={props.name} />
                    ) : (
                        <input className={'form-control'} {...field} id={props.name} disabled />
                    )}
                    {fieldState.error && (
                        <>
                            <span className="form-control-feedback rioglyph rioglyph-error-sign" />
                            <span className="help-block">
                                <span>{`${props.description} is required`}</span>
                            </span>
                        </>
                    )}
                </div>
            )}
        />
    );
};

export default SimpleTextFormElement;
