import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './store';

// https://redux.js.org/usage/usage-with-typescript#define-typed-hooks
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const TEAM_RLN_MEMBERS = [
    'azure-my-rio-de:e5bedd97-b4f1-4f40-8b9e-3457528ff29c', // matthias.seibert-ext@rio.cloud
    'azure-my-rio-de:5eb0d242-abb4-4bbd-8732-2ecf1c317494', // martin.kasparvanlaak@rio.cloud
    'azure-my-rio-de:2f9b0c9d-9d75-42e4-8df0-69ae31cc0a44', // peter.sander-ext@rio.cloud
    'azure-my-rio-de:7fdbb696-a43a-48c7-b602-89cdb2391958', // stefan.weidner@rio.cloud
    'azure-my-rio-de:691654c0-54e4-46cd-b886-9f103c6498bc', // tobias.hermann-ext@rio.cloud
    'azure-my-rio-de:8a0ddd59-80c7-402a-9234-ba085726e946', // viktar.khomich@rio.cloud
];

export const useIsTeamRlnMember = () => {
    const user = useAppSelector((state) => state.tokenHandling.idToken?.sub);
    return user ? TEAM_RLN_MEMBERS.includes(user) : false;
};
