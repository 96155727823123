import { useSelector } from 'react-redux';
import { RootState } from '../../../../../configuration/setup/store.ts';

export const AccountInfo = () => {
    const { accountName } = useSelector((state: RootState) => ({
        accountName: state.partnerIntegration.accountName,
    }));

    return (
        <>
            <h2>Account Info</h2>
            <p data-cy="partner-integration-page-account-info">Name: {accountName && <strong>{accountName}</strong>}</p>
        </>
    );
};
