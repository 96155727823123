export interface PermissionStatement {
    service: string;
    permissions: string[];
}

export const SupportedApi = {
    IOT_EVENTS: 'iot-events',
    SERVICECARE: 'servicecare',
    TIMED: 'timed',
    TACHO: 'tacho',
    ASSETS: 'assets',
    DRIVERS: 'drivers',
    PERFORM: 'perform',
} as const;

type ObjectValues<T> = T[keyof T];

export type supportedApis = ObjectValues<typeof SupportedApi>;

interface Api {
    id: supportedApis;
    name: string;
    scopes: string[];
    permissions: PermissionStatement[] | undefined;
    deprecated?: boolean;
}

export const apis: Api[] = [
    {
        id: 'iot-events',
        name: 'Telematics data',
        scopes: ['iot-events.read'],
        permissions: [{ service: 'iot-events', permissions: ['iot-events.read'] }],
    },
    {
        id: 'servicecare',
        name: 'Maintenance',
        scopes: ['vehiclestate.read'],
        permissions: [{ service: 'servicecare', permissions: ['read-write'] }],
    },
    {
        id: 'timed',
        name: 'Driving and rest periods',
        scopes: ['driving-time.read'],
        permissions: [
            {
                service: 'drivingtime',
                permissions: ['driving-time.read'],
            },
        ],
    },
    {
        id: 'tacho',
        name: 'Tacho and driver card data',
        scopes: ['tachograph-partner.read'],
        permissions: [{ service: 'tachograph-services', permissions: ['file-data.read', 'file-list.read'] }],
    },
    {
        id: 'assets',
        name: 'Assets',
        scopes: ['asset-administration.read'],
        permissions: [{ service: 'asset-administration', permissions: ['asset.read'] }],
    },
    {
        id: 'drivers',
        name: 'Drivers',
        scopes: ['drivers.read'],
        permissions: [{ service: 'driver-administration', permissions: ['driver.read'] }],
    },
    {
        id: 'perform',
        name: 'Perform',
        scopes: [
            'asset-administration.read',
            'drivers.read',
            'perform.read',
            'operating-conditions.read',
            'operating-conditions.write',
            'perform.partner-api.read',
        ],
        permissions: [
            {
                service: 'asset-administration',
                permissions: ['asset.read'],
            },
            {
                service: 'driver-administration',
                permissions: ['driver.read'],
            },
        ],
    },
];
