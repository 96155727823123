import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { fleetAdminField, FleetAdminRequest } from '../../../../app/types';
import { useEffect, useState } from 'react';
import { FormInputField } from '../FormInputField';
import { FormattedMessage } from 'react-intl';
import { emailSchema, nameSchema, phoneSchema, reasonSchema } from '../schema';
import { AccountType } from '../../../../services/schemas/accountsBackendCodec';
import { LanguageSelect, Locale } from '../LanguageSelect.tsx';

interface AddFleetAdminDialogProps {
    accountName: string;
    accountType: string | undefined;
    showDialog: boolean;
    setShowDialog: (value: boolean) => void;
    handleAddAdminConfirm: (fleetAdmin: FleetAdminRequest) => void;
}

const emptyFleetAdminRequest: FleetAdminRequest = {
    firstname: undefined,
    lastname: undefined,
    email: undefined,
    phoneNumber: undefined,
    language: undefined,
    reason: undefined,
};

type FormErrors = { [key: string]: string | undefined } | undefined;

function getAdminTypeFor(accountType: string | undefined) {
    switch (accountType) {
        case AccountType.CARRIER:
            return 'Fleet Admin';
        case AccountType.BUYER:
            return 'Buyer Admin';
        case AccountType.SUPPLIER:
            return 'Supplier Admin';
        default:
            return 'Fleet Admin';
    }
}

export const AddAdminDialog = (props: AddFleetAdminDialogProps) => {
    const { accountName, accountType, showDialog, setShowDialog, handleAddAdminConfirm } = props;

    const [fleetAdminToCreate, setFleetAdminToCreate] = useState(emptyFleetAdminRequest);
    const [formError, setFormError] = useState<FormErrors>({});

    useEffect(() => {
        setFormError({
            [fleetAdminField.FIRST_NAME]:
                fleetAdminToCreate.firstname !== undefined && nameSchema.validate(fleetAdminToCreate.firstname).error
                    ? 'helpgang.addFleetAdminDialog.name.error'
                    : undefined,
            [fleetAdminField.LAST_NAME]:
                fleetAdminToCreate.lastname !== undefined && nameSchema.validate(fleetAdminToCreate.lastname).error
                    ? 'helpgang.addFleetAdminDialog.name.error'
                    : undefined,
            [fleetAdminField.EMAIL]:
                fleetAdminToCreate.email !== undefined && emailSchema.validate(fleetAdminToCreate.email).error
                    ? 'helpgang.addFleetAdminDialog.email.error'
                    : undefined,
            [fleetAdminField.PHONE_NUMBER]:
                fleetAdminToCreate.phoneNumber !== undefined &&
                fleetAdminToCreate.phoneNumber !== '' &&
                phoneSchema.validate(fleetAdminToCreate.phoneNumber).error
                    ? 'helpgang.addFleetAdminDialog.phone.error'
                    : undefined,
            [fleetAdminField.REASON]:
                fleetAdminToCreate.reason !== undefined &&
                fleetAdminToCreate.reason !== '' &&
                reasonSchema.validate(fleetAdminToCreate.reason).error
                    ? 'helpgang.addFleetAdminDialog.reason.error'
                    : undefined,
        });
    }, [fleetAdminToCreate]);

    const handlePropertyChange = (propertyName: string, value: string) => {
        setFleetAdminToCreate((prevState) => ({
            ...prevState,
            [propertyName]: value,
        }));
    };

    const isNullOrEmpty = (s: string | null | undefined) => {
        return s == null || s.trim().length === 0;
    };

    const isInvalidPhoneNumber = (s: string | null | undefined) => {
        if (s === null || s === undefined || s === '') {
            return false;
        } else {
            return !!phoneSchema.validate(fleetAdminToCreate.phoneNumber).error;
        }
    };

    const isValidForm = () =>
        fleetAdminToCreate.firstname !== '' &&
        fleetAdminToCreate.firstname !== undefined &&
        fleetAdminToCreate.lastname !== '' &&
        fleetAdminToCreate.lastname !== undefined &&
        fleetAdminToCreate.email !== '' &&
        fleetAdminToCreate.email !== undefined &&
        !emailSchema.validate(fleetAdminToCreate.email).error &&
        fleetAdminToCreate.reason !== '' &&
        fleetAdminToCreate.reason !== undefined &&
        (fleetAdminToCreate.phoneNumber ? !phoneSchema.validate(fleetAdminToCreate.phoneNumber).error : true);

    const onConfirm = () => {
        handleAddAdminConfirm(fleetAdminToCreate);
        setFleetAdminToCreate(emptyFleetAdminRequest);
    };

    const onCancel = () => {
        setShowDialog(false);
        setFleetAdminToCreate(emptyFleetAdminRequest);
    };

    const getModalContent = () => (
        <div data-cy={'modal-content-add-fleet-admin'} className="form-group">
            <p>
                <FormattedMessage
                    id={'helpgang.addFleetAdminDialog.content'}
                    values={{ b: (chunks) => <b>{chunks}</b>, accountName: accountName }}
                />
            </p>
            <FormInputField
                dataAttribute="add-fleet-admin-dialog-first-name-input"
                translationId={'helpgang.addFleetAdminDialog.firstName'}
                value={fleetAdminToCreate.firstname}
                propertyName={fleetAdminField.FIRST_NAME}
                formError={formError && formError[fleetAdminField.FIRST_NAME]}
                autoFocus={true}
                required={true}
                onValueChange={handlePropertyChange}
            />
            <FormInputField
                dataAttribute="add-fleet-admin-dialog-last-name-input"
                translationId={'helpgang.addFleetAdminDialog.lastName'}
                value={fleetAdminToCreate.lastname}
                propertyName={fleetAdminField.LAST_NAME}
                formError={formError && formError[fleetAdminField.LAST_NAME]}
                required={true}
                onValueChange={handlePropertyChange}
            />
            <FormInputField
                dataAttribute="add-fleet-admin-dialog-email-input"
                translationId={'helpgang.addFleetAdminDialog.email'}
                placeholder={'max.mustermann@example.com'}
                value={fleetAdminToCreate.email}
                propertyName={fleetAdminField.EMAIL}
                hasError={isNullOrEmpty(fleetAdminToCreate.email)}
                formError={formError && formError[fleetAdminField.EMAIL]}
                required={true}
                onValueChange={handlePropertyChange}
            />
            <FormInputField
                dataAttribute="add-fleet-admin-dialog-phone-input"
                translationId={'helpgang.addFleetAdminDialog.phone'}
                placeholder={'+4989123456789'}
                value={fleetAdminToCreate.phoneNumber}
                propertyName={fleetAdminField.PHONE_NUMBER}
                hasError={isInvalidPhoneNumber(fleetAdminToCreate.phoneNumber)}
                formError={formError && formError[fleetAdminField.PHONE_NUMBER]}
                required={false}
                onValueChange={handlePropertyChange}
            />
            <LanguageSelect
                handleLocaleChange={(value: Locale) => handlePropertyChange(fleetAdminField.LANGUAGE, value)}
            />

            <FormInputField
                dataAttribute="add-fleet-admin-dialog-reason-input"
                translationId={'helpgang.addFleetAdminDialog.reason'}
                value={fleetAdminToCreate.reason}
                propertyName={fleetAdminField.REASON}
                formError={formError && formError[fleetAdminField.REASON]}
                required={true}
                onValueChange={handlePropertyChange}
            />

            <div className={'callout callout-warning text-color-warning line-height-125rel margin-bottom-10'}>
                <FormattedMessage id={'helpgang.addFleetAdminDialog.selfInviteWarning'} />
            </div>
        </div>
    );

    const adminType = getAdminTypeFor(accountType);
    return (
        <ConfirmationDialog
            show={showDialog}
            bsSize={'sm'}
            title={<FormattedMessage id={'helpgang.addFleetAdminDialog.title'} values={{ adminType }} />}
            content={getModalContent()}
            confirmButtonText={<FormattedMessage id={'helpgang.confirmationDialog.create'} />}
            disableConfirm={!isValidForm()}
            onClickConfirm={onConfirm}
            cancelButtonText={<FormattedMessage id={'helpgang.confirmationDialog.cancel'} />}
            onClickCancel={onCancel}
            useOverflow={false}
        />
    );
};
