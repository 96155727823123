import { useState } from 'react';
import SimpleDialog from '@rio-cloud/rio-uikit/SimpleDialog';
import { Account } from '../../../../../services/schemas/accountsBackendCodec';
import { EditAccountModalContent } from './EditAccountModalContent';

export const EditAccount = ({ account }: { account: Account }) => {
    const [showDialog, setShowDialog] = useState(false);

    return (
        <>
            <button className={'btn btn-default'} onClick={() => setShowDialog(true)}>
                Edit Account
            </button>

            <SimpleDialog
                show={showDialog}
                bsSize={'lg'}
                title={'Edit Account'}
                content={<EditAccountModalContent accountInSidebar={account} />}
                onClose={() => setShowDialog(false)}
            />
        </>
    );
};
