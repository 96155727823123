import { stringify } from 'yaml';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import { CSSProperties } from 'react';
import { apis, PermissionStatement } from './knownPartnerApis';
import { Client } from '../../../../services/schemas/clientRegistryCodec';

const determineRowStyle = (
    deprecated: boolean | undefined,
    hasMatchingScopes: boolean,
    hasMatchingPermissionStatement: boolean,
): CSSProperties => {
    if (deprecated) {
        return { backgroundColor: 'rgba(245, 143, 68, .25)' };
    } else if (hasMatchingScopes && hasMatchingPermissionStatement) {
        return { backgroundColor: 'rgba(26, 229, 26, .25)' };
    } else if (hasMatchingScopes) {
        return { backgroundColor: 'rgba(245, 143, 68, .25)' };
    } else if (hasMatchingPermissionStatement) {
        return { backgroundColor: 'rgba(245, 143, 68, .25)' };
    }
    return {};
};

export const ApiReferenceTable = ({ client }: { client?: Client }) => {
    return (
        <>
            {client !== undefined && (
                <div className={'panel panel-info margin-top-5'}>
                    <div className={'panel-heading text-center'}>
                        Disclaimer: The highlighting is experimental and might not be completely accurate.
                    </div>
                </div>
            )}
            <div className={'margin-top-15 padding-left-20 padding-right-20'}>
                <table className={'table table-condensed'}>
                    <thead>
                        <tr>
                            <th className={'padding-left-5'}>API</th>
                            <th>Scopes</th>
                            <th>Permissions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {apis
                            .filter((it) => !it.deprecated)
                            .map((api, idx) => {
                                const hasMatchingScopes = client?.scopes
                                    ? api.scopes.every((apiScope) => client.scopes.includes(apiScope))
                                    : false;
                                const filteredClientPermissionStatements =
                                    client?.partnerIntegrationPermissionTemplates.filter((it) =>
                                        api.permissions?.map((foo) => foo.service)?.includes(it.service),
                                    );
                                const hasMatchingPermissionStatementService = filteredClientPermissionStatements
                                    ? filteredClientPermissionStatements.length > 0
                                    : false;
                                let hasMatchingPermissionStatement: boolean;
                                if (api.permissions && hasMatchingPermissionStatementService) {
                                    const matchingClientPermissions = filteredClientPermissionStatements!.flatMap(
                                        (it) => it.permissions,
                                    );
                                    hasMatchingPermissionStatement = api.permissions
                                        .flatMap((it) => it.permissions)
                                        .every((it) => matchingClientPermissions.includes(it));
                                } else {
                                    hasMatchingPermissionStatement = false;
                                }
                                return (
                                    <tr
                                        key={`a_${idx}`}
                                        style={determineRowStyle(
                                            api.deprecated,
                                            hasMatchingScopes,
                                            hasMatchingPermissionStatement,
                                        )}
                                    >
                                        <td className={'text-color-dark align-top'}>{api.name}</td>
                                        <td className={'align-top'}>
                                            <pre
                                                className={hasMatchingScopes ? 'text-medium' : ''}
                                                style={{ backgroundColor: 'transparent' }}
                                            >
                                                {stringify(api.scopes)}
                                            </pre>
                                        </td>
                                        <td className={'align-top'}>
                                            {api.permissions ? (
                                                <Permissions
                                                    apiPermissionStatement={api.permissions}
                                                    hasMatchingPermissionStatement={hasMatchingPermissionStatement}
                                                />
                                            ) : null}
                                        </td>
                                        {api.deprecated && <DeprecationWarning />}
                                        {(hasMatchingScopes || hasMatchingPermissionStatement) && (
                                            <MatchingInfo
                                                hasMatchingScopes={hasMatchingScopes}
                                                hasMatchingPermissionStatement={hasMatchingPermissionStatement}
                                            />
                                        )}
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
            <div className={'row text-center margin-top-15'}>
                <span className={'rioglyph rioglyph-ok-sign text-color-success'} />: Scope and permission of client
                matches
                <span className={'rioglyph rioglyph-ok-sign text-color-warning margin-left-25'} />: Either scope or
                permission of client matches
            </div>
        </>
    );
};

const DeprecationWarning = () => (
    <OverlayTrigger
        placement={'right'}
        overlay={
            <Tooltip id="tooltip" allowOnTouch>
                Deprecated
            </Tooltip>
        }
    >
        <td className={'table-action'}>
            <span className={'text-color-warning rioglyph rioglyph-warning-sign margin-right-10'} />
        </td>
    </OverlayTrigger>
);

const MatchingInfo = ({
    hasMatchingScopes,
    hasMatchingPermissionStatement,
}: {
    hasMatchingScopes: boolean;
    hasMatchingPermissionStatement: boolean;
}) => (
    <OverlayTrigger
        placement={'right'}
        overlay={
            <Tooltip id={'tooltip'} allowOnTouch>
                <ul style={{ textAlign: 'left', margin: 0 }}>
                    {hasMatchingScopes && <li>Matching scopes</li>}
                    {hasMatchingPermissionStatement && <li>Matching permissions</li>}
                </ul>
            </Tooltip>
        }
    >
        <td className={'table-action'}>
            <span
                className={
                    hasMatchingScopes && hasMatchingPermissionStatement
                        ? 'text-color-success rioglyph rioglyph-ok-sign margin-right-10'
                        : 'text-color-warning rioglyph rioglyph-ok-sign margin-right-10'
                }
            />
        </td>
    </OverlayTrigger>
);

const Permissions = ({
    apiPermissionStatement,
    hasMatchingPermissionStatement,
}: {
    apiPermissionStatement: PermissionStatement[];
    hasMatchingPermissionStatement: boolean;
}) => (
    <>
        <pre className={hasMatchingPermissionStatement ? 'text-medium' : ''} style={{ backgroundColor: 'transparent' }}>
            {stringify(apiPermissionStatement)}
        </pre>
    </>
);
