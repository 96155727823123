import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import { decode } from './iotsDecoder';

const ExternalUserC = t.type({
    id: t.string,
    account_id: t.string,
    first_name: t.string,
    last_name: t.string,
    email: t.union([t.string, t.undefined, t.null]),
    phone_number: t.union([t.string, t.undefined, t.null]),
    preferred_login: t.string,
    preferred_language: t.string,
    group_ids: t.array(t.string),
    //    ... other fields currently ignored
});
const UserC = t.type({
    id: t.string,
    accountId: t.string,
    firstName: t.string,
    lastName: t.string,
    email: t.union([t.string, t.undefined, t.null]),
    phoneNumber: t.union([t.string, t.undefined, t.null]),
    preferred_login: t.string,
    preferredLanguage: t.string,
    groupIds: t.array(t.string),
    //    ... other fields currently ignored
});
const UsersApiResponseC = t.type({
    items: t.array(ExternalUserC),
});
const UserGroupC = t.type({
    id: t.string,
    name: t.string,
    //    ... other fields currently ignored
});
const UserGroupsResponseC = t.type({ items: t.array(UserGroupC) });

export type ExternalUser = t.TypeOf<typeof ExternalUserC>;
export type User = t.TypeOf<typeof UserC>;
export type UsersApiResponse = t.TypeOf<typeof UsersApiResponseC>;
export type UserGroup = t.TypeOf<typeof UserGroupC>;
export type UserGroupsResponse = t.TypeOf<typeof UserGroupsResponseC>;

export const decodeUserResponse = (parsedObject: unknown): ExternalUser => decode(parsedObject, ExternalUserC);
export const decodeUsersApiResponse = (parsedObject: unknown): UsersApiResponse =>
    decode(parsedObject, UsersApiResponseC);

export const decodeUserGroupsResponse = (parsedObject: unknown): UserGroupsResponse =>
    decode(parsedObject, UserGroupsResponseC);

export const mapExternalToInternalUser = (externalUser: ExternalUser): User => ({
    id: externalUser.id,
    accountId: externalUser.account_id,
    firstName: externalUser.first_name,
    lastName: externalUser.last_name,
    email: externalUser.email,
    phoneNumber: externalUser.phone_number,
    preferred_login: externalUser.preferred_login,
    preferredLanguage: externalUser.preferred_language,
    groupIds: externalUser.group_ids,
});

export const decodeAndConvertUsersApiResponse = (rawResult: unknown): User[] =>
    pipe(rawResult, decodeUsersApiResponse, (it) => it.items.map((eUser) => mapExternalToInternalUser(eUser)));
