import { createSelector } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { config } from '../../config';
import { prepareHeaders, responseHandler, RIO_AUDIT_LOG_HEADER } from './utils';
import {
    decodeAndConvertUsersApiResponse,
    decodeUserGroupsResponse,
    decodeUserResponse,
    ExternalUser,
    User,
    UserGroup,
    UserGroupsResponse,
} from './schemas/userAdminBackendCodec';
import { pipe } from 'fp-ts/function';
import { v4 as uuid } from 'uuid';
import { AccountType } from './schemas/accountsBackendCodec';

const getUserPutResource = (userId: string, user: UserMerged) => {
    const userRequestBody: { [key: string]: any } = {
        id: userId,
        account_id: user.accountId,
        first_name: user.firstName,
        last_name: user.lastName,
        group_ids: user.groupIds,
        preferred_language: user.preferredLanguage ? user.preferredLanguage : 'en-GB',
    };
    if (user.email === undefined) {
        // while it is generally possible to invite users with phone number as login, we currently only support inviting users via email
        throw new Error('Invalid user. Email has to be provided');
    }
    userRequestBody['preferred_login'] = 'email';
    userRequestBody['email'] = user.email;
    if (user.phoneNumber) {
        userRequestBody['phone_number'] = user.phoneNumber;
    }
    return userRequestBody;
};

const getAdminGroup = (accountType: string | undefined, userGroupsResponse: UserGroupsResponse) => {
    switch (accountType) {
        case AccountType.CARRIER:
            return userGroupsResponse.items.find((it) => it.name === 'Fleet admin');
        case AccountType.SUPPLIER:
            return userGroupsResponse.items.find((it) => it.name === 'Supplier admin');
        case AccountType.BUYER:
            return userGroupsResponse.items.find((it) => it.name === 'Buyer admin');
        default:
            return userGroupsResponse.items.find((it) => it.name === 'Fleet admin');
    }
};

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.backend.USERADMIN_SERVICE, prepareHeaders }),
    tagTypes: ['User'],
    endpoints: (build) => ({
        fetchUser: build.query<ExternalUser, { userId: string }>({
            query: ({ userId }) => ({
                url: `/users/${userId}`,
                responseHandler,
            }),
            transformResponse: (rawResult: unknown) => decodeUserResponse(rawResult),
        }),
        inviteUser: build.mutation<void, { user: UserMerged; reason: string }>({
            query: ({ user, reason }) => {
                const userId = uuid();
                const userRequestBody = getUserPutResource(userId, user as any);

                return {
                    url: `/users/${userId}`,
                    method: 'PUT',
                    body: userRequestBody,
                    headers: {
                        [RIO_AUDIT_LOG_HEADER]: reason,
                        'If-None-Match': '*', // if this header is not present, the request will fail with 404
                    },
                    responseHandler,
                };
            },
            invalidatesTags: ['User'],
        }),
        deleteUser: build.mutation<void, { userId: string; reason: string }>({
            query: ({ userId, reason }) => ({
                url: `/users/${userId}`,
                method: 'DELETE',
                headers: { [RIO_AUDIT_LOG_HEADER]: reason },
                responseHandler,
            }),
        }),
        fetchGroupsInAccount: build.query<UserGroup[], { accountId: string }>({
            query: ({ accountId }) => ({
                url: `/groups?account_id=${accountId}&limit=1000`,
                responseHandler,
            }),
            transformResponse: (rawResult: unknown) => pipe(rawResult, decodeUserGroupsResponse, (it) => it.items),
        }),

        // just for testing purposes
        fetchAllUsers: build.query<User[], void>({
            query: () => ({
                url: '/users',
                responseHandler,
            }),
            transformResponse: decodeAndConvertUsersApiResponse,
            providesTags: ['User'],
        }),
        inviteAdmin: build.mutation<null, { user: UserMerged; reason: string }>({
            queryFn: async (arg, _queryApi, _extraOptions, fetchBQ) => {
                const { user, reason } = arg;
                const groupsLimit = 1000;

                const userGroupsResult = await fetchBQ({
                    url: `/groups?account_id=${user.accountId}&limit=${groupsLimit}`,
                    responseHandler,
                });
                if (userGroupsResult.error) {
                    return { error: userGroupsResult.error as FetchBaseQueryError };
                }
                const userGroupsResponse = decodeUserGroupsResponse(userGroupsResult.data);
                if (userGroupsResponse.items.length === groupsLimit) {
                    return {
                        error: {
                            status: 'FETCH_ERROR',
                            data: undefined,
                            error: `Too many (=${groupsLimit}) groups found. Please contact Team Network.`,
                        },
                    };
                }

                const adminGroup = getAdminGroup(user.accountType, userGroupsResponse);
                if (!adminGroup) {
                    return {
                        error: {
                            status: 'FETCH_ERROR',
                            data: undefined,
                            error: 'Could not find fleet admin group',
                        },
                    };
                }

                const adminToInvite = {
                    ...user,
                    groupIds: [adminGroup.id],
                };

                const userId = uuid();
                const userPutResource = getUserPutResource(userId, adminToInvite);

                const result = await fetchBQ({
                    url: `/users/${userId}`,
                    method: 'PUT',
                    body: userPutResource,
                    headers: {
                        [RIO_AUDIT_LOG_HEADER]: reason,
                        'If-None-Match': '*', // if this header is not present, the request will fail with 404
                    },
                    responseHandler,
                });

                if (result.error) {
                    return { error: result.error as FetchBaseQueryError };
                }

                return { data: null };
            },
        }),
    }),
});

export const {
    useLazyFetchUserQuery,
    useFetchAllUsersQuery,
    useLazyFetchGroupsInAccountQuery,
    useInviteUserMutation,
    useDeleteUserMutation,
    useInviteAdminMutation,
} = usersApi;

export const selectUsersResult = usersApi.endpoints.fetchAllUsers.select();

const emptyUsers: ExternalUser[] = [];

export const selectAllUsers = createSelector(selectUsersResult, (usersResult) => usersResult?.data ?? emptyUsers);

interface UserBasics {
    firstName: string;
    lastName: string;
    accountId: string;
    groupIds: string[];
}

interface EmailAndPhone {
    email: string;
    phoneNumber: string;
}

type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U];

type UserMerged = UserBasics &
    AtLeastOne<EmailAndPhone> & { accountType: string | undefined; preferredLanguage?: string };
