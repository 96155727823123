import { AccountDeletionDialog } from '../../dialogs/AccountDeletionDialog';
import React, { useEffect, useState } from 'react';
import { Account } from '../../../../../services/schemas/accountsBackendCodec';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { resetAccountsSidebar } from '../accountSidebarSlice';
import { useIntl } from 'react-intl';
import { useDeleteAccountMutation } from '../../../../../services/accountsApi';
import { useAppDispatch } from '../../../../../../configuration/setup/hooks';

export const DeleteAccount = ({ account }: { account: Account }) => {
    const accountToDelete = { id: account.id, name: account.name, type: account.accountType };

    const [showDialog, setShowDialog] = useState(false);

    const [
        deleteAccountRtk,
        { isLoading: deleteIsLoading, isSuccess: deleteIsSuccess, isError: deleteIsError, error: deleteError },
    ] = useDeleteAccountMutation();

    const intl = useIntl();
    const dispatch = useAppDispatch();

    const handleClick = (evt: React.MouseEvent<HTMLElement>) => {
        evt.stopPropagation();
        setShowDialog(true);
    };

    const handleDeleteConfirm = (accountId: string, reason: string) => {
        deleteAccountRtk({ accountId, reason });
    };

    useEffect(() => {
        if (deleteIsSuccess) {
            Notification.success(intl.formatMessage({ id: 'helpgang.notification.accountDeleteSuccess' }));
            dispatch(resetAccountsSidebar());
            setShowDialog(false);
        }
    }, [deleteIsSuccess, intl, setShowDialog, dispatch]);

    useEffect(() => {
        if (deleteIsError) {
            console.error(`Could not delete account: ${deleteError}`);
            Notification.error(intl.formatMessage({ id: 'helpgang.notification.couldNotDeleteAccount' }));
            setShowDialog(false);
        }
    }, [deleteIsError, deleteError, intl, setShowDialog]);

    return (
        <>
            <button className={'btn btn-danger'} onClick={handleClick}>
                Delete Account
            </button>
            <AccountDeletionDialog
                account={accountToDelete}
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                handleDeleteConfirm={handleDeleteConfirm}
                deleteIsLoading={deleteIsLoading}
            />
        </>
    );
};
