import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../config';
import { decodeClientRegistryResponse, Client } from './schemas/clientRegistryCodec';
import { responseHandler, prepareHeaders } from './utils';

type SupportedGrantType = 'partner_integration';

export const clientRegistryApi = createApi({
    reducerPath: 'clientRegistryApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.backend.CLIENT_REGISTRY, prepareHeaders }),
    endpoints: (builder) => ({
        getAllClientsByGrantType: builder.query<Client[], SupportedGrantType>({
            query: (grantType) => ({
                url: `?grant_type=${grantType}`,
                responseHandler,
            }),
            transformResponse: (rawResult: unknown) => decodeClientRegistryResponse(rawResult),
        }),
    }),
});

export const { useGetAllClientsByGrantTypeQuery } = clientRegistryApi;
