import { ChangeEvent, useEffect, useState } from 'react';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormInputField } from '../FormInputField';
import { Account, LifeCycleState } from '../../../../services/schemas/accountsBackendCodec';
import { resetAccountsSidebar } from '../sidebar/accountSidebarSlice';
import Select from '@rio-cloud/rio-uikit/Select';
import { getLockedBecauseOptions } from '../sidebar/formOptions';
import { useAppDispatch } from '../../../../../configuration/setup/hooks';
import { useChangeLifeCycleStateMutation } from '../../../../services/accountsApi';

export enum Intention {
    activate = 'activate',
    lock = 'lock',
    unknown = '<unknown>',
}
const getIntention = (lifeCycleState: LifeCycleState) => {
    if (lifeCycleState === LifeCycleState.locked) return Intention.activate;
    if (lifeCycleState === LifeCycleState.active) return Intention.lock;
    else return Intention.unknown;
};

interface AccountLifeCycleChangeDialogProps {
    account: Account;
}

export const AccountLifeCycleChange = ({ account }: AccountLifeCycleChangeDialogProps) => {
    const [showDialog, setShowDialog] = useState(false);

    const [confirmationText, setConfirmationText] = useState('');
    const [reason, setReason] = useState<string | undefined>(undefined);
    const [lockedBecause, setLockedBecause] = useState<string | undefined>();
    const [lockedNotes, setLockedNotes] = useState<string | undefined>();

    const dispatch = useAppDispatch();
    const intl = useIntl();

    const [
        changeLifeCycleStateRtk,
        {
            isLoading: lifeCycleStateChangeIsLoading,
            isSuccess: lifeCycleStateChangeIsSuccess,
            isError: lifeCycleStateChangeIsError,
            error: lifeCycleStateChangeError,
        },
    ] = useChangeLifeCycleStateMutation();

    useEffect(() => {
        if (lifeCycleStateChangeIsSuccess) {
            Notification.success(intl.formatMessage({ id: 'helpgang.notification.accountUpdateSuccess' }));
            dispatch(resetAccountsSidebar());
            setShowDialog(false);
        }
    }, [lifeCycleStateChangeIsSuccess, intl, dispatch]);

    useEffect(() => {
        if (lifeCycleStateChangeIsError) {
            console.error(`Could not update account: ${JSON.stringify(lifeCycleStateChangeError)}`);
            Notification.error(intl.formatMessage({ id: 'helpgang.notification.couldNotUpdateAccount' }));
        }
    }, [lifeCycleStateChangeIsError, lifeCycleStateChangeError, intl]);

    const resetState = () => {
        setConfirmationText('');
        setReason(undefined);
    };

    const handleReasonChange = (_propertyName: string, value: string) => setReason(value);
    const isReasonValid = () => reason && reason.trim().length > 0;
    const isConfirmationTextValid = () => getIntention(account.lifeCycleState).toUpperCase() === confirmationText;

    const isFormValid = () =>
        reason
            ? isReasonValid() &&
              isConfirmationTextValid() &&
              getIntention(account.lifeCycleState) !== Intention.unknown &&
              (getIntention(account.lifeCycleState) === Intention.activate || lockedBecause !== undefined)
            : false;

    const handleConfirmationTextChange = (event: ChangeEvent<HTMLInputElement>) =>
        setConfirmationText(event.target.value);

    const getModalContent = () => (
        <div data-cy={'modal-content-change-lifecycle-state'}>
            <div>
                <FormInputField
                    dataAttribute="life-cycle-change-dialog-reason-input"
                    translationId={'helpgang.addFleetAdminDialog.reason'}
                    value={reason}
                    propertyName={'reason'}
                    formError={reason?.length === 0 ? 'helpgang.deleteUserDialog.reason.error' : undefined}
                    required={true}
                    onValueChange={handleReasonChange}
                    id={'reasonInputField'}
                />
            </div>
            <p>{`Do you want to ${getIntention(account.lifeCycleState)} this account?`}</p>
            {getIntention(account.lifeCycleState) === Intention.lock && (
                <div className="col-12 col-sm-4 form-group">
                    <div className="form-group">
                        <label htmlFor={'lockedReasonFormControl'}>Locked because:</label>
                        <Select
                            placeholder="Please select..."
                            options={getLockedBecauseOptions(lockedBecause)}
                            onChange={(item) => {
                                setLockedBecause(item?.id);
                            }}
                            id={'lockedReasonFormControl'}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="lockedNotesFormControl">Locked notes:</label>
                        <textarea
                            className={'form-control'}
                            id={'lockedNotesFormControl'}
                            name={'lockedNotes'}
                            rows={5}
                            maxLength={255}
                            value={lockedNotes}
                            onChange={(evt) => setLockedNotes(evt.currentTarget.value)}
                        />
                    </div>
                </div>
            )}
            <p>
                <FormattedMessage id={'helpgang.modal.typeConfirmation'} />:{' '}
                <span className="text-bold text-color-danger pointer-events-none user-select-none">
                    {getIntention(account.lifeCycleState).toUpperCase()}
                </span>
            </p>
            <div className="form-group">
                <input
                    className="form-control"
                    type="text"
                    value={confirmationText}
                    onChange={handleConfirmationTextChange}
                    data-cy={'update-account-dialog-confirmation-input'}
                />
            </div>
        </div>
    );

    const onClose = () => {
        resetState();
        setShowDialog(false);
    };

    const handleConfirmButtonClick = () => {
        if (getIntention(account.lifeCycleState) == Intention.activate) {
            reason &&
                changeLifeCycleStateRtk({
                    accountId: account.id,
                    intention: getIntention(account.lifeCycleState),
                    reason: reason,
                });
        } else {
            reason &&
                changeLifeCycleStateRtk({
                    accountId: account.id,
                    intention: getIntention(account.lifeCycleState),
                    reason: reason,
                    lifeCycleStateInformation: {
                        lockedBecause: lockedBecause!,
                        lockedNotes: lockedNotes,
                    },
                });
        }
    };

    return (
        <>
            <button
                type="button"
                className={getBtnClass(account)}
                disabled={account.lifeCycleState !== 'active' && account.lifeCycleState !== 'locked'}
                onClick={(_e) => {
                    setShowDialog(true);
                }}
            >
                {getBtnText(account)}
            </button>

            <ConfirmationDialog
                show={showDialog}
                title={
                    <div className="text-capitalize-first-word">{`${getIntention(
                        account.lifeCycleState,
                    )} Account`}</div>
                }
                content={getModalContent()}
                bsSize={'md'}
                onClickConfirm={handleConfirmButtonClick}
                onClickCancel={onClose}
                cancelButtonText={<FormattedMessage id={'helpgang.confirmationDialog.cancel'} />}
                confirmButtonText={
                    <div className="text-capitalize-first-word">{`${getIntention(
                        account.lifeCycleState,
                    )} Account`}</div>
                }
                useOverflow={true}
                disableConfirm={!isFormValid() && !lifeCycleStateChangeIsLoading}
            />
        </>
    );
};

const getBtnClass = (account: Account) => {
    return account.lifeCycleState === 'active'
        ? 'btn btn-warning'
        : account.lifeCycleState === 'locked'
          ? 'btn btn-success'
          : 'btn btn-primary';
};

const getBtnText = (account: Account) => {
    return account.lifeCycleState === 'active'
        ? 'Lock Account'
        : account.lifeCycleState === 'locked'
          ? 'Activate Account'
          : 'Unknown';
};
