import Joi from 'joi';

export const emailSchema = Joi.string()
    .email({ tlds: { allow: false } })
    .max(255)
    .allow(null);

export const nameSchema = Joi.string().trim().max(255).required();

export const reasonSchema = Joi.string().trim().max(255).required();

// Pattern from user-administration-service: @Pattern(regexp = "^|\\+[1-9][0-9]{5,13}$")
export const phoneSchema = Joi.string()
    .regex(/^\+[1-9][0-9][ 0-9]{4,14}$/) // allow more characters here to account for whitespaces
    .replace(/ /g, '')
    .min(7) // = 5 + 2 (matching the min number of characters from user-admin-service regex)
    .max(15) // = 13 + 2 (matching the max number of characters from user-admin-service regex)
    .messages({ 'string.pattern.base': `Phone number must have form +89123456.` })
    .required();
