import { Account } from '../../../../services/schemas/accountsBackendCodec';
import { CompactTable } from './CompactTable';
import { lockingOptions } from './formOptions';
import { AccountLifeCycleInformationUpdate } from '../dialogs/AccountLifeCycleInformationUpdate';
import { FormattedDateTime } from '../../../common/FormattedDateTime';

export const AccountDetails = (props: { account: Account }) => {
    const account = props.account;

    const mapLockedBecauseIdToLable = (id: string) => {
        return lockingOptions.find((option) => id === option.id)?.label;
    };

    return (
        <>
            <div className="width-100pct">
                <div className={'table-responsive'}>
                    <CompactTable account={account} />
                </div>
                <div className={'table-responsive'}>
                    {account.lifeCycleStateInformation && (
                        <>
                            <h6 className={'margin-left-10 margin-top-25'}>Locking info:</h6>
                            <table className={'table table-head-filled table-condensed'}>
                                <thead>
                                    <tr>
                                        <th />
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Locked Because</td>
                                        <td>
                                            {mapLockedBecauseIdToLable(account.lifeCycleStateInformation.lockedBecause)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Locked At</td>
                                        <td>
                                            {account.lifeCycleStateInformation.lockedAt ? (
                                                <FormattedDateTime value={account.lifeCycleStateInformation.lockedAt} />
                                            ) : (
                                                <span className={'text-light'}>
                                                    <i>unknown</i>
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Locked Notes</td>
                                        <td>
                                            <textarea
                                                value={account.lifeCycleStateInformation.lockedNotes}
                                                rows={account.lifeCycleStateInformation.lockedNotes ? 5 : 3}
                                                disabled={true}
                                                style={{ width: '350px', resize: 'vertical' }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className={'pull-right margin-right-20'}>
                                <AccountLifeCycleInformationUpdate account={account} />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
