export const AccountStatus = ({ status }: { status: string | null | undefined }) => {
    switch (status) {
        case 'active':
            return <span className={'tag tag-small bg-success'}>{status}</span>;
        case 'locked':
            return <span className={'tag tag-small bg-warning'}>{status}</span>;
        default:
            return <span className={'tag tag-small'}>{status}</span>;
    }
};
