import Select, { type SelectOption } from '@rio-cloud/rio-uikit/Select';
import { FormattedMessage } from 'react-intl';

const locale = {
    Bulgarian: 'bg-BG',
    Czech: 'cs-CZ',
    Danish: 'da-DK',
    German: 'de-DE',
    Greek: 'el-GR',
    English: 'en-GB',
    Spanish: 'es-ES',
    Estonian: 'et-EE',
    Finnish: 'fi-FI',
    French: 'fr-FR',
    Croatian: 'hr-HR',
    Hungarian: 'hu-HU',
    Italian: 'it-IT',
    Korean: 'ko-KR',
    Luxembourgish: 'lb-LU',
    Lithuanian: 'lt-LT',
    Latvian: 'lv-LV',
    Norwegian: 'nb-NO',
    Dutch: 'nl-NL',
    Polish: 'pl-PL',
    Brazilian: 'pt-BR',
    Portuguese: 'pt-PT',
    Romanian: 'ro-RO',
    Slovak: 'sk-SK',
    Slovenian: 'sl-SI',
    Swedish: 'sv-SE',
} as const;

export type Locale = (typeof locale)[keyof typeof locale];

const options: (SelectOption & { id: Locale })[] = [
    { id: locale.Bulgarian, label: `🇧🇬 Bulgarian (${locale.Bulgarian})` },
    { id: locale.Czech, label: `🇨🇿 Czech (${locale.Czech})` },
    { id: locale.Danish, label: `🇩🇰 Danish (${locale.Danish})` },
    { id: locale.German, label: `🇩🇪 German (${locale.German})` },
    { id: locale.Greek, label: `🇬🇷 Greek (${locale.Greek})` },
    { id: locale.English, label: `🇬🇧 English (${locale.English})`, selected: true },
    { id: locale.Spanish, label: `🇪🇸 Spanish (${locale.Spanish})` },
    { id: locale.Estonian, label: `🇪🇪 Estonian (${locale.Estonian})` },
    { id: locale.Finnish, label: `🇫🇮 Finnish (${locale.Finnish})` },
    { id: locale.French, label: `🇫🇷 French (${locale.French})` },
    { id: locale.Croatian, label: `🇭🇷 Croatian (${locale.Croatian})` },
    { id: locale.Hungarian, label: `🇭🇺 Hungarian (${locale.Hungarian})` },
    { id: locale.Italian, label: `🇮🇹 Italian (${locale.Italian}) 🤌` },
    { id: locale.Korean, label: `🇰🇷 Korean (${locale.Korean})` },
    { id: locale.Luxembourgish, label: `🇱🇺 Luxembourgish (${locale.Luxembourgish})` },
    { id: locale.Lithuanian, label: `🇱🇹 Lithuanian (${locale.Lithuanian})` },
    { id: locale.Latvian, label: `🇱🇻 Latvian (${locale.Latvian})` },
    { id: locale.Norwegian, label: `🇳🇴 Norwegian (${locale.Norwegian})` },
    { id: locale.Dutch, label: `🇳🇱 Dutch (${locale.Dutch})` },
    { id: locale.Brazilian, label: `🇧🇷 Brazilian (${locale.Brazilian})` },
    { id: locale.Portuguese, label: `🇵🇹 Portuguese (${locale.Portuguese})` },
    { id: locale.Romanian, label: `🇷🇴 Romanian (${locale.Romanian})` },
    { id: locale.Slovak, label: `🇸🇰 Slovak (${locale.Slovak})` },
    { id: locale.Slovenian, label: `🇸🇮 Slovenian (${locale.Slovenian})` },
    { id: locale.Swedish, label: `🇸🇪 Swedish (${locale.Swedish})` },
    { id: locale.Polish, label: `🇵🇱 Polish (${locale.Polish})` },
];

interface Props {
    handleLocaleChange: (locale: Locale) => void;
}

export const LanguageSelect = (props: Props) => {
    const { handleLocaleChange } = props;

    return (
        <div className="col-12 col-sm-4 form-group">
            <label htmlFor="language-select">
                <FormattedMessage id={'helpgang.addFleetAdminDialog.language'} />
            </label>
            <Select
                id="language-select"
                options={options}
                onChange={(item) => handleLocaleChange(item?.id ?? locale.English)}
            />
        </div>
    );
};
