export type FleetAdminRequest = Record<FleetAdminField, string | undefined>;

export const fleetAdminField = {
    FIRST_NAME: 'firstname',
    LAST_NAME: 'lastname',
    EMAIL: 'email',
    REASON: 'reason',
    PHONE_NUMBER: 'phoneNumber',
    LANGUAGE: 'language',
} as const;

type FleetAdminField = (typeof fleetAdminField)[keyof typeof fleetAdminField];
