import { PartnerIntegrationInfo } from './PartnerIntegrationInfo';

export const PartnerIntegrationInfoWrapper = () => (
    <>
        <div className={'row margin-top-25'}>
            <div className={'col-xs-12'}>
                <h1>Partner Integration Info</h1>
            </div>
        </div>
        <div className={'row'}>
            <div className={'col-xs-12'}>
                <div className={'panel panel-default'}>
                    <div className={'panel-body'}>
                        <PartnerIntegrationInfo />
                    </div>
                </div>
            </div>
        </div>
    </>
);
