import { Controller, useFormContext } from 'react-hook-form';
import Select from '@rio-cloud/rio-uikit/Select';
import { Account } from '../../../../services/schemas/accountsBackendCodec';

export type SelectOptionType = {
    label: string;
    id: string;
    selected?: boolean;
};
export const DropDownFormElement = (props: {
    description?: string;
    name: 'taxId.taxType' | 'legalAddress.countryCode';
    rules: any;
    options: SelectOptionType[];
}) => {
    const { control } = useFormContext<Account>(); // retrieve all hook methods

    const getOptions = (selectedValue: string) =>
        props.options.map(
            (item: SelectOptionType): SelectOptionType => ({
                ...item,
                selected: item.id === selectedValue,
            }),
        );
    return (
        <Controller
            name={props.name}
            control={control}
            rules={props.rules}
            render={({ field, fieldState }) => (
                <div className={`form-group ${fieldState.error ? 'has-feedback has-error' : ''}`}>
                    <label className="control-label">{props.description}</label>
                    <Select
                        placeholder="Please select..."
                        options={getOptions(field.value)}
                        onChange={(selectedOption: any) => field.onChange(selectedOption.id)}
                    />
                    {fieldState.error && (
                        <>
                            <span className="form-control-feedback rioglyph rioglyph-error-sign" />
                            <span className="help-block">
                                <span>{` is required`}</span>
                            </span>
                        </>
                    )}
                </div>
            )}
        />
    );
};
