import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { accountsApi } from '../../../services/accountsApi';
import { partnerIntegrationApi } from '../../../services/partnerIntegrationApi';

type Status = 'idle' | 'pending';

type Integration = {
    timestamp: string;
    integrationId: string;
    clientId: string;
    accountName: string | undefined;
    accountId: string;
};

export interface PartnerIntegrationState {
    accountName: string | undefined;
    integrationId: string | undefined;
    clientId: string;
    accountId: string;
    status: Status;
    error: string | undefined;
    createdIntegrations: Array<Integration>;
}

const initialState: PartnerIntegrationState = {
    accountName: '',
    integrationId: '',
    clientId: '',
    accountId: '',
    status: 'idle',
    error: undefined,
    createdIntegrations: [],
};

const partnerIntegrationSlice = createSlice({
    name: 'partnerIntegration',
    initialState,
    reducers: {
        setAccountId: (state, action: PayloadAction<string>) => {
            state.accountId = action.payload;
        },
        setAccountName: (state, action: PayloadAction<string>) => {
            state.accountName = action.payload;
        },
        setClientId: (state, action: PayloadAction<string>) => {
            state.clientId = action.payload;
        },
        setStatus: (state, action: PayloadAction<Status>) => {
            state.status = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // fetchAccountName response handling
            .addMatcher(accountsApi.endpoints.getAccountNameById.matchPending, (state, _action) => {
                state.status = 'pending';
            })
            .addMatcher(accountsApi.endpoints.getAccountNameById.matchFulfilled, (state, action) => {
                state.accountName = action.payload;
                state.status = 'idle';
            })
            .addMatcher(accountsApi.endpoints.getAccountNameById.matchRejected, (state, action) => {
                state.error = action.error.message;
                state.status = 'idle';
                Notification.warning('Could not retrieve account info.');
            })

            // createPartnerIntegration response handling
            .addMatcher(partnerIntegrationApi.endpoints.createPartnerIntegration.matchPending, (state) => {
                state.status = 'pending';
            })
            .addMatcher(partnerIntegrationApi.endpoints.createPartnerIntegration.matchFulfilled, (state, action) => {
                state.createdIntegrations.push({
                    timestamp: new Date().toLocaleTimeString(),
                    integrationId: action.payload.integrationId,
                    clientId: action.payload.clientId,
                    accountId: action.payload.accountId,
                    accountName: state.accountName,
                });
                state.status = 'idle';
                Notification.success('Partner Integration created successfully');

                // reset
                state.accountId = '';
                state.clientId = '';
            })
            .addMatcher(partnerIntegrationApi.endpoints.createPartnerIntegration.matchRejected, (state, action) => {
                state.error = action.error.message;
                state.status = 'idle';
                Notification.error('Could not create Partner Integration');
            });
    },
});

export const partnerIntegrationReducer = partnerIntegrationSlice.reducer;
export const { setAccountId, setAccountName, setClientId, setStatus } = partnerIntegrationSlice.actions;
