import LoadMoreButton from '@rio-cloud/rio-uikit/LoadMoreButton';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import Tag from '@rio-cloud/rio-uikit/Tag';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import SortDirection from '@rio-cloud/rio-uikit/SortDirection';
import { sortByProperty, type SortDirectionType } from '@rio-cloud/rio-uikit/SortUtils';
import classNames from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';
import omit from 'lodash/fp/omit';
import { ClientDetailsDialog } from './ClientDetailsDialog';
import { ClientsTableHeader } from './ClientsTableHeader';
import { ClientsTableToolbar } from './ClientsTableToolbar';
import { useState } from 'react';
import { ApiReferenceDialog } from './ApiReferenceDialog';
import { Client } from '../../../../services/schemas/clientRegistryCodec';
import { useGetAllClientsByGrantTypeQuery } from '../../../../services/clientRegistryApi';
import { ValueWithCopyButton } from '../../../common/ValueWithCopyButton';

const DEFAULT_COLUMN_ORDER = ['id', 'name', 'email', 'scopes', 'description', 'detailedDescription'] as const;
export type Column = (typeof DEFAULT_COLUMN_ORDER)[number];

const INITIAL_DISPLAY_COUNT = import.meta.env.PROD ? 25 : 3;

const columnLabels: { [key: string]: string } = {
    id: 'Id',
    name: 'Name',
    email: 'Email',
    scopes: 'Scopes',
    description: 'Description',
    detailedDescription: 'Description',
};

const getSortDir = (sortDir: string, sortBy: Column, previousSortBy: Column) => {
    if (sortBy === previousSortBy) {
        return sortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
    }
    return SortDirection.ASCENDING;
};

export const ClientsTable = () => {
    const [searchValue, setSearchValue] = useState('');
    const [sortBy, setSortBy] = useState<Column>('id');
    const [sortDir, setSortDir] = useState<SortDirectionType>(SortDirection.ASCENDING);
    const [columnOrder] = useState(DEFAULT_COLUMN_ORDER);
    const [allColumns] = useState(DEFAULT_COLUMN_ORDER);
    const [hiddenColumns] = useState<string[]>(['description', 'detailedDescription']);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [displayedRows, setDisplayedRows] = useState(INITIAL_DISPLAY_COUNT);

    const [showClientDetailsDialog, setShowClientDetailsDialog] = useState(false);
    const [showApiReferenceModal, setShowApiReferenceModal] = useState(false);
    const [selectedClient, setSelectedClient] = useState<Client>();

    const { data: clients, isLoading, isError } = useGetAllClientsByGrantTypeQuery('partner_integration');

    const handleSearchValueChange = (newSearchValue: string) => setSearchValue(newSearchValue);

    const handleSortChange = (event: any) => {
        const newSortBy = event.currentTarget.getAttribute('data-sortby');
        handleCardSortChange(newSortBy, getSortDir(sortDir, newSortBy, sortBy));
    };

    const handleCardSortChange = (newSortBy: Column, newSortDir: SortDirectionType) => {
        setSortBy(newSortBy);
        setSortDir(newSortDir);
    };

    const handleInfoClick = (clientId: string) => {
        const requestedClient = clients?.find((it) => it.id === clientId);
        setSelectedClient(requestedClient);
        setShowClientDetailsDialog(true);
    };

    const loadMore = () =>
        setDisplayedRows((prev) => {
            const stepSize = 25;
            const availableItems = rows?.length ?? 0;
            if (prev < availableItems) {
                const sum = prev + stepSize;
                return sum > availableItems ? availableItems : sum;
            } else {
                return availableItems;
            }
        });

    const tableClassNames = classNames(
        'table',
        'table-layout-fixed',
        'table-column-overflow-hidden',
        'table-bordered',
        'table-sticky',
        'table-head-filled',
    );

    // filter for hidden columns
    const columns = columnOrder.filter((name) => !hiddenColumns.includes(name));

    // in case a search value is given, filter the data accordingly
    const searchResult = !searchValue
        ? clients
        : clients?.filter((row: any) =>
              allColumns.some((col) => row[col].toString().toLowerCase().includes(searchValue.toLowerCase())),
          );

    // filter data. We explicitly filter over all available columns
    const withoutHidden = omit(hiddenColumns);
    const filteredSearchResult = searchResult?.map((it) => ({ ...withoutHidden(it) }));

    const rows =
        sortBy && filteredSearchResult ? sortByProperty(filteredSearchResult, sortBy, sortDir) : filteredSearchResult;

    const renderRow = (row: any, col: any) => {
        switch (col) {
            case 'id': {
                return <ValueWithCopyButton value={row[col]} />;
            }
            case 'scopes': {
                return row[col].map((it: any, idx: number) => (
                    <Tag size="small" key={idx}>
                        {it}
                    </Tag>
                ));
            }
            default:
                return row[col];
        }
    };
    return (
        <>
            <div id={'TableClients'} className={'margin-top-15'}>
                <ClientDetailsDialog
                    client={selectedClient}
                    show={showClientDetailsDialog}
                    setShow={setShowClientDetailsDialog}
                />
                <ApiReferenceDialog show={showApiReferenceModal} onClose={() => setShowApiReferenceModal(false)} />
                <ClientsTableToolbar
                    isLoading={isLoading}
                    isError={isError}
                    searchValue={searchValue}
                    loadClientsClicked={buttonClicked}
                    setLoadClientsClicked={setButtonClicked}
                    handleSearchValueChange={handleSearchValueChange}
                    showApiReferenceDialog={() => setShowApiReferenceModal(true)}
                    isGetClientsButtonDisabled={!buttonClicked ? false : clients !== undefined && clients.length > 0}
                    csvExportData={
                        filteredSearchResult?.map((item) => ({
                            id: item['id'],
                            name: item['name'],
                            email: item['email'],
                            scopes: item['scopes'],
                        })) ?? ([] as any)
                    }
                />
                {isError ? (
                    <div className={'panel panel-danger'}>
                        <div className={'panel-body'}>There was a problem. Could not retrieve data</div>
                    </div>
                ) : null}
                {clients && buttonClicked && (
                    <div>
                        <table className={tableClassNames}>
                            <ClientsTableHeader
                                columns={columns}
                                labels={columnLabels}
                                sortBy={sortBy}
                                sortDir={sortDir}
                                handleSortChange={handleSortChange}
                            />
                            <tbody>
                                {isEmpty(rows) ||
                                    (rows === undefined && (
                                        <tr>
                                            <td colSpan={columns.length + 1}>
                                                <NotFoundState
                                                    outerClassName={'border-none'}
                                                    headline={'Nothing found'}
                                                    message={'Please refine your search'}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                {rows!.slice(0, displayedRows).map((row: any, index) => (
                                    <tr key={index} data-cy={'partner-integration-clients-row'}>
                                        {columns.map((col) => (
                                            <td key={col} data-field={columnLabels[col]}>
                                                <span>{renderRow(row, col)}</span>
                                            </td>
                                        ))}
                                        <td className={'table-action'}>
                                            <DisplayDetailedInfoButton onClick={() => handleInfoClick(row.id)} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {searchValue && displayedRows > rows!.length ? null : (
                            <LoadMoreButton
                                loaded={displayedRows}
                                total={rows?.length}
                                onLoadMore={loadMore}
                                loadMoreMessage={'Load more'}
                                noMoreMessage={'Everything loaded'}
                            />
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

const DisplayDetailedInfoButton = ({ onClick }: { onClick: () => void }) => (
    <OverlayTrigger
        placement={'right'}
        overlay={
            <Tooltip id="tooltip" allowOnTouch>
                Details
            </Tooltip>
        }
    >
        <button className={'btn btn-muted btn-icon-only'} onClick={onClick}>
            <span className={'rioglyph rioglyph-detail-view-info'} />
        </button>
    </OverlayTrigger>
);
