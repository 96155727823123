export function isBelowMaxLength(list: string[], maxLength: number | undefined) {
    return !maxLength || list.length <= maxLength;
}

export function isAboveMinLength(list: string[], minLength: number | undefined) {
    return !minLength || list.length >= minLength;
}

export function isValidDuns(duns: string) {
    return /^[0-9]{9}$/.test(duns);
}

export function isWithoutDuplicates<T>(list: T[]) {
    return list.length === new Set(list).size;
}
