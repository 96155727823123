import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import TableSearch from '@rio-cloud/rio-uikit/TableSearch';
import { CSVLink } from 'react-csv';

export const ClientsTableToolbar = ({
    isLoading,
    isError,
    searchValue,
    loadClientsClicked,
    setLoadClientsClicked,
    handleSearchValueChange,
    showApiReferenceDialog,
    isGetClientsButtonDisabled = false,
    csvExportData,
}: {
    isLoading: boolean;
    isError: boolean;
    searchValue: string;
    loadClientsClicked: boolean;
    setLoadClientsClicked: (arg: boolean) => void;
    handleSearchValueChange: any;
    showApiReferenceDialog: () => void;
    isGetClientsButtonDisabled?: boolean;
    csvExportData: { [key: string]: string }[];
}) => {
    return (
        <TableToolbar>
            <div className={'table-toolbar-container'}>
                <div className={'table-toolbar-group-left'}>
                    <div className={'table-toolbar-column'}>
                        <div className={'btn-toolbar table-btn-toolbar'}>
                            <button
                                className={
                                    isLoading && loadClientsClicked ? 'btn btn-primary btn-loading' : 'btn btn-primary'
                                }
                                onClick={() => setLoadClientsClicked(true)}
                                disabled={(isLoading && loadClientsClicked) || isGetClientsButtonDisabled}
                            >
                                Get Clients
                            </button>
                            <button className={'btn btn-primary'} onClick={showApiReferenceDialog}>
                                Api References
                            </button>
                            {loadClientsClicked && (
                                <CSVLink
                                    className="btn btn-primary"
                                    filename={'Clients.csv'}
                                    data={csvExportData}
                                    headers={[
                                        { label: 'Id', key: 'id' },
                                        { label: 'Name', key: 'name' },
                                        { label: 'Email', key: 'email' },
                                        { label: 'Scopes', key: 'scopes' },
                                    ]}
                                >
                                    <span className={'rioglyph-download'} />
                                </CSVLink>
                            )}
                        </div>
                    </div>
                </div>
                {loadClientsClicked && !isLoading && !isError && (
                    <div className={'table-toolbar-group-right'}>
                        <div className={'table-toolbar-column'}>
                            <TableSearch
                                value={searchValue}
                                onChange={handleSearchValueChange}
                                placeholder={'Search table ...'}
                            />
                        </div>
                    </div>
                )}
            </div>
        </TableToolbar>
    );
};
