import { useState } from 'react';

const SupportedTimezone = {
    EUROPE_BERLIN: 'Europe/Berlin',
    UTC: 'Etc/UTC',
} as const;

type SupportedTimezone = (typeof SupportedTimezone)[keyof typeof SupportedTimezone];

export const FormattedDateTime = (props: { value: string; clickable?: boolean }) => {
    const [timezone, setTimezone] = useState<SupportedTimezone>(SupportedTimezone.EUROPE_BERLIN);

    const { value, clickable = false } = props;

    if (value === '') {
        return null;
    }

    const truncatedDateString = value.replace(/\.\d+/, '');

    const onClick = () => {
        if (timezone === SupportedTimezone.EUROPE_BERLIN) {
            setTimezone(SupportedTimezone.UTC);
        } else {
            setTimezone(SupportedTimezone.EUROPE_BERLIN);
        }
    };

    const date = new Date(truncatedDateString);

    const timeFormatOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
        timeZone: timezone,
        hour12: false,
    };

    const formatter = new Intl.DateTimeFormat('en-US', timeFormatOptions);
    try {
        const formattedDate = formatter.format(date);

        return clickable ? (
            <span onClick={onClick} style={{ cursor: 'pointer' }}>
                {formattedDate}
            </span>
        ) : (
            <span>{formattedDate}</span>
        );
    } catch (e) {
        console.error(`Could not create formatted date from string "${value}". Using raw value instead.`);
        return <span>{value}</span>;
    }
};
