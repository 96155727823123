import { ChangeEvent, useEffect, useState } from 'react';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormInputField } from '../FormInputField';
import { Account } from '../../../../services/schemas/accountsBackendCodec';
import { resetAccountsSidebar } from '../sidebar/accountSidebarSlice';
import Select from '@rio-cloud/rio-uikit/Select';
import { Intention } from './AccountLifeCycleChange';
import { getLockedBecauseOptions, lockingOptions } from '../sidebar/formOptions';
import { useAppDispatch } from '../../../../../configuration/setup/hooks';
import { useChangeLifeCycleStateMutation } from '../../../../services/accountsApi';

interface AccountLifeCycleInformationUpdateProps {
    account: Account;
}

export const AccountLifeCycleInformationUpdate = ({ account }: AccountLifeCycleInformationUpdateProps) => {
    const [showDialog, setShowDialog] = useState(false);
    const [confirmationText, setConfirmationText] = useState('');
    const [reason, setReason] = useState<string | undefined>(undefined);
    const [lockedBecause, setLockedBecause] = useState<string | undefined>(
        account.lifeCycleStateInformation?.lockedBecause,
    );
    const [lockedNotes, setLockedNotes] = useState<string | undefined>(account.lifeCycleStateInformation?.lockedNotes);

    const dispatch = useAppDispatch();
    const intl = useIntl();

    const [
        changeLifeCycleStateRtk,
        {
            isLoading: lifeCycleStateChangeIsLoading,
            isSuccess: lifeCycleStateChangeIsSuccess,
            isError: lifeCycleStateChangeIsError,
            error: lifeCycleStateChangeError,
        },
    ] = useChangeLifeCycleStateMutation();

    useEffect(() => {
        if (lifeCycleStateChangeIsSuccess) {
            Notification.success(intl.formatMessage({ id: 'helpgang.notification.accountUpdateSuccess' }));
            dispatch(resetAccountsSidebar());
            setShowDialog(false);
        }
    }, [lifeCycleStateChangeIsSuccess, intl, dispatch]);

    useEffect(() => {
        if (lifeCycleStateChangeIsError) {
            console.error(`Could not update account: ${JSON.stringify(lifeCycleStateChangeError)}`);
            Notification.error(intl.formatMessage({ id: 'helpgang.notification.couldNotUpdateAccount' }));
        }
    }, [lifeCycleStateChangeIsError, lifeCycleStateChangeError, intl]);

    const resetState = () => {
        setConfirmationText('');
        setReason(undefined);
    };

    const handleReasonChange = (_propertyName: string, value: string) => setReason(value);
    const isReasonValid = () => reason && reason.trim().length > 0;
    const VALID_CONFIRMATION_TEXT = 'UPDATE';
    const isConfirmationTextValid = () => VALID_CONFIRMATION_TEXT === confirmationText;

    const isFormValid = () =>
        reason ? isReasonValid() && isConfirmationTextValid() && lockedBecause !== undefined : false;

    const handleConfirmationTextChange = (event: ChangeEvent<HTMLInputElement>) =>
        setConfirmationText(event.target.value);

    const getModalContent = () => {
        return (
            <div data-cy={'modal-content-change-lifecycle-state'}>
                <div>
                    <FormInputField
                        dataAttribute="life-cycle-change-dialog-reason-input"
                        translationId={'helpgang.addFleetAdminDialog.reason'}
                        value={reason}
                        propertyName={'reason'}
                        formError={reason?.length === 0 ? 'helpgang.deleteUserDialog.reason.error' : undefined}
                        required={true}
                        onValueChange={handleReasonChange}
                        id={'reasonInputField'}
                    />
                </div>
                <p>Do you want to update account locking information?</p>
                {
                    <div className="col-12 col-sm-4 form-group">
                        <div className="form-group">
                            <label htmlFor={'lockedReasonFormControl'}>Locked because:</label>
                            <Select
                                placeholder="Please select..."
                                options={getLockedBecauseOptions(lockedBecause)}
                                selectedOptionText={lockingOptions.find((option) => option.id === lockedBecause)?.label}
                                onChange={(item) => {
                                    setLockedBecause(item?.id);
                                }}
                                id={'lockedReasonFormControl'}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="lockedNotesFormControl">Locked notes:</label>
                            <textarea
                                className={'form-control'}
                                id={'lockedNotesFormControl'}
                                name={'lockedNotes'}
                                rows={5}
                                maxLength={255}
                                value={lockedNotes}
                                onChange={(evt) => setLockedNotes(evt.currentTarget.value)}
                            />
                        </div>
                    </div>
                }
                <p>
                    <FormattedMessage id={'helpgang.modal.typeConfirmation'} />:{' '}
                    <span className="text-bold text-color-danger pointer-events-none user-select-none">
                        {VALID_CONFIRMATION_TEXT}
                    </span>
                </p>
                <div className="form-group">
                    <input
                        className="form-control"
                        type="text"
                        value={confirmationText}
                        onChange={handleConfirmationTextChange}
                        data-cy={'update-account-locking-info-dialog-confirmation-input'}
                    />
                </div>
            </div>
        );
    };

    const onClose = () => {
        resetState();
        setShowDialog(false);
    };

    const handleConfirmButtonClick = () => {
        reason &&
            changeLifeCycleStateRtk({
                accountId: account.id,
                intention: Intention.lock,
                reason: reason,
                lifeCycleStateInformation: {
                    lockedBecause: lockedBecause!,
                    lockedNotes: lockedNotes,
                },
            });
    };

    return (
        <>
            <button
                type="button"
                className="btn btn-warning btn-outline"
                disabled={account.lifeCycleState !== 'locked'}
                onClick={(_e) => {
                    setShowDialog(true);
                }}
            >
                Update Locking Info
            </button>

            <ConfirmationDialog
                show={showDialog}
                title={'Update account locking information'}
                content={getModalContent()}
                bsSize={'md'}
                onClickConfirm={handleConfirmButtonClick}
                onClickCancel={onClose}
                cancelButtonText={<FormattedMessage id={'helpgang.confirmationDialog.cancel'} />}
                confirmButtonText={'Update Locking Info'}
                useOverflow={true}
                disableConfirm={!isFormValid() && !lifeCycleStateChangeIsLoading}
            />
        </>
    );
};
