import React, { useState } from 'react';
import { useLazyGetAccountNameByIdQuery } from '../../services/accountsApi';

export const AccountIdWithName = ({ accountId }: { accountId: string }) => {
    const [accountName, setAccountName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [fetchAccountName] = useLazyGetAccountNameByIdQuery();

    const onClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (isLoading) {
            return;
        }

        setIsLoading(true);
        fetchAccountName(accountId)
            .unwrap()
            .then(setAccountName)
            .catch((err: unknown) => {
                console.log('Could not resolve account name:', err);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            {accountName ? (
                <table className={'table table-condensed text-color-black'}>
                    <tbody>
                        <tr>
                            <td className={'padding-0 padding-bottom-5'} style={{ borderTop: 0 }}>
                                {accountId}
                            </td>
                        </tr>
                        <tr>
                            <td className={'padding-0 text-color-dark'} style={{ borderTop: 0 }}>
                                {accountName}
                            </td>
                        </tr>
                    </tbody>
                </table>
            ) : (
                <div>
                    <a href={'#'} className={'text-color-black'} onClick={onClick} style={{ textDecoration: 'none' }}>
                        {accountId}
                    </a>
                </div>
            )}
        </>
    );
};
