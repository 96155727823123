import Dialog from '@rio-cloud/rio-uikit/Dialog';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormInputField } from '../FormInputField';
import { useDeleteUserMutation, useLazyFetchUserQuery } from '../../../../services/usersApi';
import { ExternalUser } from '../../../../services/schemas/userAdminBackendCodec';

interface ParsingError {
    status: 'PARSING_ERROR';
    originalStatus: number;
    error: string;
}

const isParsingError = (err: unknown): err is ParsingError =>
    typeof err === 'object' &&
    err !== null &&
    'status' in err &&
    err.status === 'PARSING_ERROR' &&
    'originalStatus' in err &&
    typeof err.originalStatus === 'number';

interface DeleteUserDialogProps {
    showDialog: boolean;
    setShowDialog: (value: boolean) => void;
    accountId: string;
}

export const DeleteUserDialog = ({ showDialog, setShowDialog, accountId }: DeleteUserDialogProps) => {
    const [userId, setUserId] = useState<string | undefined>(undefined);
    const [reason, setReason] = useState<string | undefined>(undefined);
    const [user, setUser] = useState<ExternalUser | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const [getUserRtk] = useLazyFetchUserQuery();
    const [deleteUsrRtk] = useDeleteUserMutation();

    const intl = useIntl();

    const resetState = () => {
        setUserId(undefined);
        setUser(undefined);
        setReason(undefined);
        setLoading(false);
    };

    const onClose = () => {
        setShowDialog(false);
        resetState();
    };

    const handleUserIdChange = (_propertyName: string, value: string) => setUserId(value);
    const handleReasonChange = (_propertyName: string, value: string) => setReason(value);

    const userIsAlreadyLoaded = () => user !== undefined;

    const handleDeleteUser = () => {
        if (userIsAlreadyLoaded()) {
            user && reason && deleteUser(user, reason);
        } else {
            userId && getUser(userId);
        }
    };

    const deleteUser = async (user: ExternalUser, reason: string) => {
        if (user.account_id !== accountId) {
            Notification.error(intl.formatMessage({ id: 'helpgang.notification.deleteUserInWrongAccountError' }));
        } else {
            setLoading(true);
            try {
                await deleteUsrRtk({ userId: user.id, reason: reason }).unwrap();
                Notification.success(intl.formatMessage({ id: 'helpgang.notification.deleteUserSuccess' }));
                onClose();
            } catch (error) {
                console.log('Error during user deletion:', error);
                Notification.error(intl.formatMessage({ id: 'helpgang.notification.couldNotDeleteUser' }));
            } finally {
                setLoading(false);
            }
        }
    };

    const getUser = async (userId: string) => {
        setLoading(true);
        try {
            const user = await getUserRtk({ userId }).unwrap();
            setUser(user);
        } catch (error) {
            // {status: 'PARSING_ERROR', originalStatus: 404, data: '', error: 'Error: 404 Backend error: Not Found'}
            if (isParsingError(error) && error.originalStatus === 404) {
                Notification.error(intl.formatMessage({ id: 'helpgang.notification.userNotFound' }));
            } else {
                console.log('Error retrieving user:', error);
                Notification.error(intl.formatMessage({ id: 'helpgang.notification.couldNotDeleteUser' }));
            }
        } finally {
            setLoading(false);
        }
    };

    const isUserIdValid = () => userId === undefined || userId.length > 0;
    const isReasonValid = () => reason === undefined || reason.length > 0;
    const isValidForm = () => (userId && reason ? isUserIdValid() && isReasonValid() : false);

    const getUserDeletionConfirmationMessage = () => {
        return (
            user && (
                <div>
                    {intl.formatMessage({ id: 'helpgang.deleteUserDialog.confirmationText' })}
                    <div className={'text-bold margin-top-25'}>Name: {`${user.first_name} ${user.last_name}`}</div>
                    {user.email && <div className={'text-bold'}>Email: {user.email}</div>}
                    {user.phone_number && (
                        <div className={'text-bold'}>
                            {intl.formatMessage({ id: 'helpgang.deleteUserDialog.phoneNumber' })}: {user.phone_number}
                        </div>
                    )}
                    <div className={'text-bold'}>Account Id: {user.account_id}</div>
                </div>
            )
        );
    };

    const getUserIdForm = () => (
        <div>
            <div className="form-group">
                <FormInputField
                    dataAttribute="delete-user-dialog-user-id-input"
                    translationId={'helpgang.deleteUserDialog.userId'}
                    value={userId}
                    propertyName={'userId'}
                    formError={!isUserIdValid() ? 'helpgang.deleteUserDialog.userId.error' : undefined}
                    autoFocus={true}
                    required={true}
                    onValueChange={handleUserIdChange}
                />
                <FormInputField
                    dataAttribute="delete-user-dialog-reason-input"
                    translationId={'helpgang.deleteUserDialog.reason'}
                    value={reason}
                    propertyName={'reason'}
                    formError={!isReasonValid() ? 'helpgang.deleteUserDialog.reason.error' : undefined}
                    autoFocus={true}
                    required={true}
                    onValueChange={handleReasonChange}
                />
            </div>
        </div>
    );

    const getModalContent = () => {
        if (userIsAlreadyLoaded()) {
            return getUserDeletionConfirmationMessage();
        } else {
            return getUserIdForm();
        }
    };

    return (
        <ConfirmationDialog
            show={showDialog}
            title={<FormattedMessage id={'helpgang.deleteUserDialog.title'} />}
            content={getModalContent()}
            bsSize={Dialog.SIZE_SM}
            cancelButtonText={<FormattedMessage id={'helpgang.deleteUserDialog.cancel'} />}
            confirmButtonText={<FormattedMessage id={'helpgang.deleteUserDialog.confirm'} />}
            onClickConfirm={handleDeleteUser}
            onClickCancel={onClose}
            disableConfirm={loading || !isValidForm()}
            useOverflow={true}
        />
    );
};
