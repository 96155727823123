import * as t from 'io-ts';
import { pipe } from 'fp-ts/function';
import { decode } from './iotsDecoder';

const ExternalPartnerIntegrationC = t.intersection([
    t.type({
        integration_id: t.string,
        account_id: t.string,
        client_id: t.string,
    }),
    t.partial({ tag_id: t.union([t.string, t.null, t.undefined]) }),
]);

export type ExternalPartnerIntegration = t.TypeOf<typeof ExternalPartnerIntegrationC>;

const PartnerIntegrationC = t.type({
    integrationId: t.string,
    accountId: t.string,
    clientId: t.string,
});

export type PartnerIntegration = t.TypeOf<typeof PartnerIntegrationC>;

const PartnerIntegrationApiResponseC = t.type({ items: t.array(ExternalPartnerIntegrationC) });
export type PartnerIntegrationApiResponse = t.TypeOf<typeof PartnerIntegrationApiResponseC>;

const mapExternalToInternalPartnerIntegration = (input: ExternalPartnerIntegration): PartnerIntegration => ({
    integrationId: input.integration_id,
    clientId: input.client_id,
    accountId: input.account_id,
});

const mapAll = (input: ExternalPartnerIntegration[]): PartnerIntegration[] =>
    input.map((it) => mapExternalToInternalPartnerIntegration(it));

const decodePartnerIntegrationQueryApiResponse = (input: unknown): PartnerIntegrationApiResponse =>
    decode(input, PartnerIntegrationApiResponseC);

const decodePartnerIntegrationByIdApiResponse = (input: unknown): ExternalPartnerIntegration =>
    decode(input, ExternalPartnerIntegrationC);

const extractItems = (input: PartnerIntegrationApiResponse): ExternalPartnerIntegration[] => input.items;

export const convertPartnerIntegrationQueryApiResponse = (input: unknown): PartnerIntegration[] =>
    pipe(input, decodePartnerIntegrationQueryApiResponse, extractItems, mapAll);

export const convertPartnerIntegrationByIdApiResponse = (input: unknown): PartnerIntegration =>
    pipe(input, decodePartnerIntegrationByIdApiResponse, mapExternalToInternalPartnerIntegration);
