/* eslint-disable @typescript-eslint/ban-types */
import { Component, ErrorInfo, ReactNode } from 'react';

export class ErrorBoundary extends Component<{ children?: ReactNode }, {}> {
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error(error + 'with error info' + errorInfo);
    }

    render() {
        return this.props.children;
    }
}
