import { SearchHeader, searchIdentifier } from './SearchHeader';
import { useEffect, useState } from 'react';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { SelectOption } from '@rio-cloud/rio-uikit/Select';
import { PartnerIntegrationTable } from './PartnerIntegrationTable';
import { PartnerIntegration } from '../../../../services/schemas/partnerIntegrationCodec';
import { useLazyGetByIdQuery, useLazyGetByQueryParamQuery } from '../../../../services/partnerIntegrationApi';

export const PartnerIntegrationInfo = () => {
    const [selectedSearchOptionId, setSelectedSearchOptionId] = useState(searchIdentifier.CLIENT_ID);
    const [searchString, setSearchString] = useState('');
    const [searchResult, setSearchResult] = useState<PartnerIntegration[]>([]);
    const [isPending, setIsPending] = useState(false);
    const [initialDataLoaded, setInitialDataLoaded] = useState<undefined | 'loading' | 'loaded'>(undefined);

    const handleIdentifierSelection = (item: SelectOption | undefined) => item && setSelectedSearchOptionId(item.id);

    const [trigger, result] = useLazyGetByQueryParamQuery();

    const [triggerById, resultById] = useLazyGetByIdQuery();

    useEffect(() => {
        if (result.currentData) {
            setSearchResult(result.currentData);
        } else {
            setSearchResult([]);
        }
    }, [result.currentData]);

    useEffect(() => {
        if (resultById.currentData) {
            setSearchResult([resultById.currentData]);
        } else {
            setSearchResult([]);
        }
    }, [resultById.currentData]);

    useEffect(() => {
        if (result.isFetching || resultById.isFetching) {
            setIsPending(true);
        } else {
            setIsPending(false);
        }
    }, [result.isFetching, resultById.isFetching]);

    useEffect(() => {
        if (result.isLoading || resultById.isLoading) {
            setInitialDataLoaded('loading');
        } else if (initialDataLoaded === 'loading' && !result.isLoading && !resultById.isLoading) {
            setInitialDataLoaded('loaded');
        }
    }, [result.isLoading, resultById.isLoading, initialDataLoaded]);

    useEffect(() => {
        if (
            (result.error && (result.error as any).originalStatus === 404) ||
            (resultById.error && (resultById.error as any).originalStatus === 404)
        ) {
            console.log(typeof resultById.error);
            console.log(resultById.error);
            Notification.warning('Could not find any partner integration');
        }
        if (result.isError || resultById.isError) {
            setSearchResult([]);
        }
    }, [result.error, resultById.error, result.isError, resultById.isError]);

    const handleClick = () => {
        switch (selectedSearchOptionId) {
            case searchIdentifier.CLIENT_ID:
                trigger({ searchType: 'client_id', id: searchString });
                break;
            case searchIdentifier.ACCOUNT_ID:
                trigger({ searchType: 'account_id', id: searchString });
                break;
            case searchIdentifier.INTEGRATION_ID:
                triggerById({ integrationId: searchString });
                break;
            default:
                console.error('INVALID SEARCH OPTION');
        }
    };

    return (
        <>
            <SearchHeader
                handleSearch={handleClick}
                handleIdentifierSelection={handleIdentifierSelection}
                pending={isPending}
                searchString={searchString}
                setSearchString={setSearchString}
                searchTypeValue={selectedSearchOptionId}
            />
            {isPending && (
                <div className="form-group">
                    <Spinner isDoubleSized />
                </div>
            )}
            {initialDataLoaded === 'loaded' && !isPending ? (
                searchResult.length > 0 ? (
                    <PartnerIntegrationTable tableItems={searchResult} />
                ) : (
                    <div>Nothing found</div>
                )
            ) : null}
        </>
    );
};
