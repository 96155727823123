import { AccountInfo } from './create/AccountInfo';
import { CreatedIntegrationsTable } from './create/CreatedIntegrationsTable';
import { CreatePartnerIntegration } from './create/CreatePartnerIntegration';
import { Clients } from './clients/Clients';
import { PartnerIntegrationInfoWrapper } from './info/PartnerIntegrationInfoWrapper';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { resetAccountsSidebar } from '../accounts/sidebar/accountSidebarSlice';

export const PartnerIntegrationPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetAccountsSidebar());
    }, [dispatch]);

    return (
        <div className="container" data-cy="partner-integration-page">
            <div className="row">
                <div className="col-xs-12">
                    <h1>Create Partner Integration</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <CreatePartnerIntegration />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <AccountInfo />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <CreatedIntegrationsTable />
                        </div>
                    </div>
                </div>
            </div>
            <PartnerIntegrationInfoWrapper />
            <Clients />
        </div>
    );
};
