import { Provider } from 'react-redux';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { main } from './configuration';
import { config } from './config';
import { App } from './features/app/App';
import { NoMatch } from './features/app/NoMatch';
import { ErrorBoundary } from './features/app/ErrorBoundary';
import { createRoot } from 'react-dom/client';
import { User } from 'oidc-client-ts';
import { InvalidLogin } from './features/app/InvalidLogin';
import { store } from './configuration/setup/store';
import { handleLoginRedirect } from './configuration/login/redirect';

const renderApplication = ({ user, logout }: { user: User; logout: () => void }) => {
    const container = document.getElementById('root');
    const root = createRoot(container!);

    let showApp = true;
    try {
        showApp = user.profile.sub.startsWith('azure-my-rio-de:');
    } catch (e) {
        console.error(e);
    }

    // Note that we need to use the base "Router" with a "hash" history
    // because the "HashRouter" doesn't allow handing in a history
    // from the outside. So this is effectively a "HashRouter" despite
    // that not being obvious here
    if (showApp) {
        root.render(
            <ErrorBoundary>
                <Provider store={store}>
                    <HashRouter>
                        <Routes>
                            <Route path={'*'} element={<App logout={logout} />} />
                            <Route path={'/error'} element={<NoMatch />} />
                        </Routes>
                    </HashRouter>
                </Provider>
            </ErrorBoundary>,
        );
    } else {
        root.render(<InvalidLogin />);
    }
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else {
    main(renderApplication);
}
