import { combineReducers, configureStore } from '@reduxjs/toolkit';
import appReducer from '../../features/app/appSlice';
import tokenHandlingReducer from '../tokenHandling/tokenSlice.ts';
import langReducer from '../lang/langSlice';
import loginReducer from '../login/loginSlice';
import { clientRegistryApi } from '../../features/services/clientRegistryApi';
import { setupListeners } from '@reduxjs/toolkit/query';
import { partnerIntegrationApi } from '../../features/services/partnerIntegrationApi';
import { accountsApi } from '../../features/services/accountsApi';
import { clientMetricsApi } from '../../features/services/metricsApi';
import { partnerIntegrationReducer } from '../../features/components/tabs/partner-integrations/partnerIntegrationSlice';
import { accountSidebarReducer } from '../../features/components/tabs/accounts/sidebar/accountSidebarSlice';
import { accountsSliceReducer } from '../../features/components/tabs/accounts/accountsSlice';
import { usersApi } from '../../features/services/usersApi';

export const rootReducer = combineReducers({
    lang: langReducer,
    app: appReducer,
    login: loginReducer,
    tokenHandling: tokenHandlingReducer,
    partnerIntegration: partnerIntegrationReducer,
    accountSidebar: accountSidebarReducer,
    accountsSliceReducer,
    [clientRegistryApi.reducerPath]: clientRegistryApi.reducer,
    [partnerIntegrationApi.reducerPath]: partnerIntegrationApi.reducer,
    [accountsApi.reducerPath]: accountsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [clientMetricsApi.reducerPath]: clientMetricsApi.reducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) =>
    configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat([
                clientRegistryApi.middleware,
                partnerIntegrationApi.middleware,
                accountsApi.middleware,
                usersApi.middleware,
                clientMetricsApi.middleware,
            ]),
        preloadedState: preloadedState,
    });

export const store = setupStore();

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch'];
