import { DropDownFormElement } from '../DropDownFormElement';
import SimpleTextFormElement from '../../sidebar/SimpleTextFormElement';
import { formOptions, getValidTaxTypeOptions } from '../../sidebar/formOptions';
import { useFormContext } from 'react-hook-form';
import { Account } from '../../../../../services/schemas/accountsBackendCodec';
import DunsListTextAreaFormElement from '../DunsListTextAreaFormElement';

interface Props {
    onSubmit: (account: Account) => void;
    accountIsInUnexpectedState: boolean;
    accountType: string | undefined;
}

export const EditAccountForm = (props: Props) => {
    const { onSubmit, accountIsInUnexpectedState, accountType } = props;
    const { handleSubmit, formState, getValues } = useFormContext<Account>(); // retrieve all hook methods
    return (
        <div className="margin-25">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={'row'}>
                    <div className={'col-md-6'}>
                        <SimpleTextFormElement
                            name="id"
                            description="Account Id"
                            rules={{ required: true }}
                            isEditable={false}
                        />
                        <SimpleTextFormElement
                            name="name"
                            description="Account Name*"
                            rules={{ required: true }}
                            isEditable={true}
                        />
                        <SimpleTextFormElement
                            name="tenant"
                            description="Account Tenant"
                            rules={{ required: true }}
                            isEditable={false}
                        />
                        <SimpleTextFormElement
                            name="registrationChannel"
                            description="Registration Channel"
                            rules={{ required: true }}
                            isEditable={false}
                        />
                        <SimpleTextFormElement
                            name="taxId.value"
                            description="Tax Id"
                            rules={{ required: false }}
                            isEditable={true}
                        />
                        <DropDownFormElement
                            name="taxId.taxType"
                            description="Type of Tax Id"
                            rules={{ required: false }}
                            options={getValidTaxTypeOptions(getValues('tenant'))}
                        />
                        <DunsListTextAreaFormElement name="dunsNumbers" description="DUNS" accountType={accountType} />
                    </div>

                    <div className={'col-md-6'}>
                        <SimpleTextFormElement
                            name="legalAddress.line1"
                            description="Address Line 1 (usually street & no.)"
                            rules={{ required: false }}
                            isEditable={true}
                        />
                        <SimpleTextFormElement
                            name="legalAddress.line2"
                            description="Address Line 2 (usually unused)"
                            rules={{ required: false }}
                            isEditable={false}
                        />
                        <SimpleTextFormElement
                            name="legalAddress.line3"
                            description="Address Line 3 (usually unused)"
                            rules={{ required: false }}
                            isEditable={false}
                        />
                        <SimpleTextFormElement
                            name="legalAddress.city"
                            description="Town/City"
                            rules={{ required: false }}
                            isEditable={true}
                        />
                        <SimpleTextFormElement
                            name="legalAddress.postCode"
                            description="Post Code"
                            rules={{ required: false }}
                            isEditable={true}
                        />
                        <DropDownFormElement
                            name="legalAddress.countryCode"
                            description="Country"
                            rules={{ required: true }}
                            options={formOptions}
                        />
                        <SimpleTextFormElement
                            name="lifeCycleState"
                            description="Lifecycle State"
                            rules={{ required: true }}
                            isEditable={false}
                        />
                    </div>
                </div>
                {accountIsInUnexpectedState && (
                    <div className="margin-top-25 alert alert-dismissible alert-danger">
                        <div className="display-flex">
                            <span className="text-color-danger text-size-h4 margin-right-10 rioglyph rioglyph rioglyph-error-sign"></span>
                            <div>
                                <strong className="text-size-16">Invalid state</strong>
                                <div className="margin-y-5">
                                    This account is in an unexpected Lifecycle Cycle State and therefore not editable.
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="margin-top-25 margin-bottom-15 display-flex justify-content-end">
                    <button
                        type={'submit'}
                        className={'btn btn-primary'}
                        disabled={!formState.isDirty || !formState.isValid || accountIsInUnexpectedState}
                    >
                        Update Account
                    </button>
                </div>
            </form>
        </div>
    );
};
