import SimpleDialog from '@rio-cloud/rio-uikit/SimpleDialog';
import Switch from '@rio-cloud/rio-uikit/Switch';
import { stringify } from 'yaml';
import { useEffect, useState } from 'react';
import { setYamlState, shouldShowYaml } from './showYamlCookie';
import { ApiReferenceTable } from './ApiReferenceTable';
import { ApiComparison } from './ApiComparison';
import { Client } from '../../../../services/schemas/clientRegistryCodec';

type SelectedTab = 'details' | 'api-info' | 'compare';

export const ClientDetailsDialog = ({
    client,
    show,
    setShow,
}: {
    client: Client | undefined;
    show: boolean;
    setShow: (show: boolean) => void;
}) => {
    const [showYaml, setShowYaml] = useState(false);
    const [selectedTab, setSelectedTab] = useState<SelectedTab>('details');

    useEffect(() => {
        if (shouldShowYaml()) {
            setShowYaml(true);
        }
    }, []);

    const toggle = () => {
        setYamlState({ showYaml: !showYaml });
        setShowYaml(!showYaml);
    };

    const onClose = () => {
        setSelectedTab('details');
        setShow(false);
    };

    const contentHeader = (
        <ul className={'nav nav-pills'}>
            <li className={selectedTab === 'details' ? 'active' : ''} onClick={() => setSelectedTab('details')}>
                <span>Details</span>
            </li>
            <li className={selectedTab === 'api-info' ? 'active' : ''} onClick={() => setSelectedTab('api-info')}>
                <span>API reference</span>
            </li>
            {client !== undefined && (
                <li className={selectedTab === 'compare' ? 'active' : ''} onClick={() => setSelectedTab('compare')}>
                    <span>Compare</span>
                </li>
            )}
        </ul>
    );

    let content: any;
    switch (selectedTab) {
        case 'details': {
            content = (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }} className={'margin-5'}>
                        <Switch onChange={toggle} checked={showYaml} labelPosition={'left'}>
                            <span>Show as YAML</span>
                        </Switch>
                    </div>
                    <div>
                        <pre>{showYaml ? stringify(client) : JSON.stringify(client, null, 2)}</pre>
                    </div>
                </div>
            );
            break;
        }
        case 'api-info': {
            content = <ApiReferenceTable client={client} />;
            break;
        }
        case 'compare': {
            content = <ApiComparison client={client} />;
            break;
        }
        default: {
            content = null;
        }
    }

    const contentFull = (
        <div>
            {contentHeader}
            {content}
        </div>
    );

    return (
        <SimpleDialog
            show={show}
            title={client ? `Client Details: '${client.name}'` : 'Client Details'}
            content={contentFull}
            onClose={onClose}
        />
    );
};
