import classNames from 'classnames';
import SortArrows from '@rio-cloud/rio-uikit/SortArrows';
import { MouseEventHandler } from 'react';
import { Column } from './ClientsTable';

interface Label {
    [key: string]: string;
}

export const ClientsTableHeader = ({
    columns,
    labels,
    sortBy,
    sortDir,
    handleSortChange,
}: {
    columns: string[];
    labels: Label;
    sortBy: string;
    sortDir: string;
    handleSortChange: MouseEventHandler<HTMLTableHeaderCellElement>;
}) => {
    const renderTableHead = (column: string, label: string, sortBy: string, sortDir: string) => {
        const sortColumns: Column[] = ['id', 'name'];
        const isSortColum = sortColumns.includes(column as any);

        const tableHeadClassNames = isSortColum ? classNames('user-select-none', 'sort-column') : '';

        return (
            <th
                key={column}
                className={tableHeadClassNames}
                onClick={isSortColum ? handleSortChange : () => {}}
                data-field={column}
                data-sortby={column}
                title={label}
            >
                <span>
                    {isSortColum ? sortBy === column ? <SortArrows direction={sortDir} /> : <SortArrows /> : null}
                    <span>{label}</span>
                </span>
            </th>
        );
    };

    return (
        <thead>
            <tr>
                {columns.map((column) => renderTableHead(column, labels[column], sortBy, sortDir))}
                <th className={'table-action'} />
            </tr>
        </thead>
    );
};
