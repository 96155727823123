import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../configuration/setup/store';

interface AccountsSlice {
    selectedAccountId: string | undefined;
}

const initialState: AccountsSlice = {
    selectedAccountId: undefined,
};

const accountsSlice = createSlice({
    name: 'accounts',
    initialState,
    reducers: {
        setSelectedAccountId: (state, action: PayloadAction<string | undefined>) => {
            state.selectedAccountId = action.payload;
        },
        resetSelectedAccountId: (state) => {
            state.selectedAccountId = undefined;
        },
    },
});

export const getSelectedAccountId = (state: RootState) => state.accountsSliceReducer.selectedAccountId;

export const accountsSliceReducer = accountsSlice.reducer;
export const { setSelectedAccountId, resetSelectedAccountId } = accountsSlice.actions;
