import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';
import { ClientMetricsData, useGetMetricByIdQuery } from '../../services/metricsApi';
import { useState } from 'react';
import { FormattedDateTime } from './FormattedDateTime';

interface Props {
    id: string;
    autoLoad?: boolean;
}

export const PartnerIntegrationStatus = (props: Props) => {
    const { id, autoLoad = true } = props;

    const [load, setLoad] = useState(autoLoad);

    return load ? (
        <StatusWrapper id={id} />
    ) : (
        <SimpleTooltip content={'Click to load'} placement={'auto'}>
            <div className="display-flex">
                <Disabled onClick={() => setLoad(true)} />
            </div>
        </SimpleTooltip>
    );
};

const StatusWrapper = ({ id }: { id: string }) => {
    const { data, isLoading } = useGetMetricByIdQuery({ id });
    return (
        <>
            {isLoading ? (
                <span className="btn btn-muted btn-loading btn-icon-only" />
            ) : data ? (
                <Status data={data} />
            ) : (
                <SimpleTooltip content={'No token requests found for the last week'} placement={'auto'}>
                    <div className="display-flex">
                        <NoInfo />
                    </div>
                </SimpleTooltip>
            )}
        </>
    );
};

const Status = ({ data }: { data: ClientMetricsData[] }) => {
    const { hasSuccess, hasFailure, lastSuccess, lastFailure } = analyzeData(data);

    const tooltip = getTooltip(lastSuccess, lastFailure);

    return (
        <>
            <SimpleTooltip content={tooltip} placement={'auto'}>
                <div className="display-flex column-gap-5">
                    {hasSuccess && <Success />}
                    {hasFailure && <Error />}
                    {!hasSuccess && !hasFailure && <NoInfo />}
                </div>
            </SimpleTooltip>
        </>
    );
};

const getTooltip = (lastSuccess: string, lastFailure: string) => {
    if (lastSuccess && lastFailure) {
        return (
            <div>
                <div>
                    <span>Last success: </span>
                    <FormattedDateTime value={lastSuccess} />
                </div>
                <div>
                    <span>Last failure: </span>
                    <FormattedDateTime value={lastFailure} />
                </div>
            </div>
        );
    } else if (lastSuccess) {
        return (
            <div>
                <span>Last success: </span>
                <FormattedDateTime value={lastSuccess} />
            </div>
        );
    } else if (lastFailure) {
        return (
            <div>
                <span>Last failure: </span>
                <FormattedDateTime value={lastFailure} />
            </div>
        );
    } else {
        return 'No token requests found for the last week';
    }
};

const analyzeData = (data: ClientMetricsData[]) => {
    let hasSuccess = false;
    let hasFailure = false;
    let lastSuccess = '';
    let lastFailure = '';

    data.forEach((it) => {
        if (it.statusCode.startsWith('2')) {
            hasSuccess = true;
            if (!lastSuccess) {
                lastSuccess = it.lastSeen;
            } else if (it.lastSeen > lastSuccess) {
                lastSuccess = it.lastSeen;
            }
        } else if (it.statusCode.startsWith('4') || it.statusCode.startsWith('5')) {
            hasFailure = true;
            if (!lastFailure) {
                lastFailure = it.lastSeen;
            } else if (it.lastSeen > lastFailure) {
                lastFailure = it.lastSeen;
            }
        }
    });

    lastSuccess = lastSuccess.replace(/\.\d+/, '');
    lastFailure = lastFailure.replace(/\.\d+/, '');

    return { hasSuccess, hasFailure, lastSuccess, lastFailure };
};

const Disabled = ({ onClick }: { onClick: () => void }) => (
    <span className="bg-info rounded-circle width-15 height-15" onClick={onClick} style={{ cursor: 'pointer' }} />
);
const NoInfo = () => <span className="bg-gray rounded-circle width-15 height-15" />;
const Success = () => <span className="bg-success rounded-circle width-15 height-15" />;
const Error = () => <span className="bg-danger rounded-circle width-15 height-15" />;
