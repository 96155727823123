import Cookies from 'js-cookie';

interface ShowYamlState {
    showYaml: boolean;
}

const COOKIE_NAME = 'show-yaml';

export const shouldShowYaml = (): boolean => {
    const cookieValue = Cookies.get(COOKIE_NAME);
    if (cookieValue) {
        try {
            const parsedCookieValue: ShowYamlState = JSON.parse(window.atob(cookieValue));
            return parsedCookieValue.showYaml;
        } catch {
            return false;
        }
    } else {
        return false;
    }
};

export const setYamlState = (state: ShowYamlState) => {
    Cookies.set(COOKIE_NAME, window.btoa(JSON.stringify(state)), { secure: false, expires: 365 });
};
