import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { isAboveMinLength, isBelowMaxLength, isValidDuns, isWithoutDuplicates } from '../validators';
import { Account, AccountType } from '../../../../services/schemas/accountsBackendCodec';

// allow this FormElement to be used in any Form
// as long as the FormValues contain a field with the name 'dunsNumbers'
type FieldValuesIncludingDuns = {
    dunsNumbers: string[];
} & FieldValues;

const DunsListTextAreaFormElement = <T extends FieldValuesIncludingDuns>(props: {
    description?: string;
    name: 'dunsNumbers' & Path<T>;
    disabled?: boolean;
    accountType?: string;
}) => {
    const { control } = useFormContext<Account>(); // retrieve all hook methods

    const separator = '\n';
    const fixedLength = props.accountType === AccountType.CARRIER ? undefined : 1;

    const fromList = (value: string[]) => {
        return value.join(separator);
    };

    const toList = (raw: string) => {
        return raw.split(separator);
    };

    const nonEmpty = (list: string[]) => list.filter((it) => it.length > 0);

    return (
        <Controller
            name={props.name}
            control={control}
            rules={{
                validate: {
                    wrongLength: (dunsNumbers: string[]) => {
                        return (
                            (isAboveMinLength(nonEmpty(dunsNumbers), fixedLength) &&
                                isBelowMaxLength(nonEmpty(dunsNumbers), fixedLength)) ||
                            'Logistics accounts require exactly one DUNS'
                        );
                    },
                    correctDunsFormat: (dunsNumbers: string[]) =>
                        nonEmpty(dunsNumbers).every((duns) => isValidDuns(duns)) ||
                        'At least one DUNS does not have 9 digits',
                    noDuplicates: (dunsNumbers: string[]) =>
                        isWithoutDuplicates(nonEmpty(dunsNumbers)) || 'Duplicate DUNS',
                },
            }}
            render={({ field, fieldState }) => (
                <div className={`form-group ${fieldState.error ? 'has-feedback has-error' : ''}`}>
                    <label className="control-label" htmlFor={props.name}>
                        {props.description} (newline separated)
                    </label>
                    <textarea
                        className={'form-control'}
                        name={field.name}
                        value={fromList(field.value)}
                        onChange={(it) => field.onChange(toList(it.target.value))}
                        onBlur={field.onBlur}
                        id={props.name}
                        data-testid={props.name}
                        disabled={props.disabled ? props.disabled : false}
                        rows={Math.min(field.value.length + 1, 7)}
                    />
                    {fieldState.error && (
                        <>
                            <span className="form-control-feedback rioglyph rioglyph-error-sign" />
                            <span className="help-block">
                                <span>{`${fieldState.error.message}`}</span>
                            </span>
                        </>
                    )}
                </div>
            )}
        />
    );
};

export default DunsListTextAreaFormElement;
