import { ClientMetricsData } from '../../../services/metricsApi';

export const clusterData = (
    data: ClientMetricsData[],
): {
    success: ClientMetricsData[];
    failure: ClientMetricsData[];
    other: ClientMetricsData[];
} => {
    const success: ClientMetricsData[] = [];
    const failure: ClientMetricsData[] = [];
    const other: ClientMetricsData[] = [];
    data.forEach((it) => {
        if (it.statusCode.startsWith('2')) {
            success.push(it);
        } else if (it.statusCode.startsWith('4') || it.statusCode.startsWith('5')) {
            failure.push(it);
        } else {
            other.push(it);
        }
    });
    return { success, failure, other };
};
