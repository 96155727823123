import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import { getShowAccountSidebar, getSidebarAccount, setShowAccountSidebar } from './accountSidebarSlice';
import { EditAccount } from '../dialogs/editAccount/EditAccount';
import { AccountDetails } from './AccountDetails';
import { AccountLifeCycleChange } from '../dialogs/AccountLifeCycleChange';
import { resetSelectedAccountId } from '../accountsSlice';
import { AddAdminToAccount } from './adminFunctionality/AddAdminToAccount';
import { DeleteAccount } from './adminFunctionality/DeleteAccount';
import { DeleteUserInAccount } from './adminFunctionality/DeleteUserInAccount';
import { Account } from '../../../../services/schemas/accountsBackendCodec';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';

export const AccountsSidebar = () => {
    const show = useAppSelector(getShowAccountSidebar);
    const accountInSidebar = useAppSelector(getSidebarAccount);
    const dispatch = useAppDispatch();

    return (
        <Sidebar
            title={'Account Info'}
            titleClassName={'padding-left-10'}
            closed={!show}
            onClose={() => {
                dispatch(setShowAccountSidebar(false));
                dispatch(resetSelectedAccountId());
            }}
            position={'right'}
            fly={true}
            width={500}
        >
            <div className={'height-100pct'}>
                {accountInSidebar && (
                    <>
                        <AccountDetails account={accountInSidebar} />
                        <ActionArea account={accountInSidebar} />
                    </>
                )}
            </div>
        </Sidebar>
    );
};

const ActionArea = ({ account }: { account: Account }) => (
    <div className={'display-flex flex-nowrap justify-content-center margin-top-50'}>
        <div className={'display-grid gap-20'}>
            <div className={'display-grid gap-25 grid-cols-1 margin-bottom-25'}>
                <EditAccount account={account} />
            </div>
            <div className={'display-grid gap-25 grid-cols-2'}>
                <AccountLifeCycleChange account={account} />
                <DeleteAccount account={account} />
            </div>
            <div className={'display-grid gap-25 grid-cols-2'}>
                <AddAdminToAccount account={account} />
                <DeleteUserInAccount account={account} />
            </div>
        </div>
    </div>
);
