import { Account, LifeCycleState } from '../../../../../services/schemas/accountsBackendCodec';
import { useEffect, useState } from 'react';
import { EditAccountConfirmation } from './EditAccountConfirmation';
import { FormProvider, useForm } from 'react-hook-form';
import { resetAccountsSidebar } from '../../sidebar/accountSidebarSlice';
import { EditAccountForm } from './EditAccountForm';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { useIntl } from 'react-intl';
import { useAppDispatch } from '../../../../../../configuration/setup/hooks';
import { useUpdateAccountMutation } from '../../../../../services/accountsApi';
import { ZalandoErrorNotificationContent } from '../../../../common/ZalandoErrorNotificationContent';

const editAccountStep = {
    EDIT_ACCOUNT: 'EDIT_ACCOUNT',
    CONFIRM_EDIT_ACCOUNT: 'CONFIRM_EDIT_ACCOUNT',
} as const;

type EditAccountStep = (typeof editAccountStep)[keyof typeof editAccountStep];

const accountIsLocked = (account: Account) => account.lifeCycleState === LifeCycleState.locked;
const accountIsActive = (account: Account) => account.lifeCycleState === LifeCycleState.active;
const accountIsInUnexpectedState = (account: Account) => !accountIsLocked(account) && !accountIsActive(account);

export const EditAccountModalContent = ({ accountInSidebar }: { accountInSidebar: Account }) => {
    const [accountToUpdate, setAccountToUpdate] = useState(accountInSidebar);
    const [step, setStep] = useState<EditAccountStep>(editAccountStep.EDIT_ACCOUNT);

    const dispatch = useAppDispatch();
    const intl = useIntl();

    const [
        updateAccountRtk,
        { isLoading: updateIsLoading, isSuccess: updateIsSuccess, isError: updateIsError, error: updateError },
    ] = useUpdateAccountMutation();

    const form = useForm<Account>({
        defaultValues: accountInSidebar,
        mode: 'all',
    });

    // Trigger validation at initial loading of the form
    // This is reasonable for UX as we only use the form for updating and the initial state should be valid.
    // If the initial state is not valid we should show the user directly which fields prevent them from saving.
    useEffect(() => {
        const initializeForm = async () => {
            await form.trigger();
        };
        initializeForm().catch(console.error);
    }, [form.trigger]);

    useEffect(() => {
        if (accountInSidebar.id !== accountToUpdate.id) {
            setAccountToUpdate(accountInSidebar);
            form.reset(accountInSidebar);
        }
    }, [accountInSidebar, form.reset, accountToUpdate.id]);

    useEffect(() => {
        if (updateIsSuccess) {
            Notification.success(intl.formatMessage({ id: 'helpgang.notification.accountUpdateSuccess' }));
            dispatch(resetAccountsSidebar());
        }
    }, [updateIsSuccess, intl, dispatch]);

    useEffect(() => {
        if (updateIsError) {
            Notification.error(
                <ZalandoErrorNotificationContent rtkError={updateError} />,
                intl.formatMessage({ id: 'helpgang.notification.couldNotUpdateAccount' }),
            );
        }
    }, [updateIsError, updateError, intl]);

    const handleUpdateConfirm = (account: Account) => (reason: string) => {
        updateAccountRtk({ account, reason });
    };

    const onSubmit = (editedAccountData: Account) => {
        setAccountToUpdate(editedAccountData);
        setStep(editAccountStep.CONFIRM_EDIT_ACCOUNT);
    };

    return step === editAccountStep.EDIT_ACCOUNT ? (
        <FormProvider {...form}>
            <EditAccountForm
                onSubmit={onSubmit}
                accountIsInUnexpectedState={accountIsInUnexpectedState(accountInSidebar)}
                accountType={accountToUpdate.accountType}
            />
        </FormProvider>
    ) : (
        <EditAccountConfirmation
            account={accountToUpdate}
            oldAccount={accountInSidebar}
            dirtyFields={form.formState.dirtyFields}
            updateDialogIsLoading={updateIsLoading}
            handleUpdateConfirm={handleUpdateConfirm(accountToUpdate)}
            cancelUpdate={() => setStep(editAccountStep.EDIT_ACCOUNT)}
        />
    );
};
