import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../config';
import { prepareHeaders, responseHandler } from './utils';

export interface ClientMetricsData {
    statusCode: string;
    lastSeen: string;
    amount: string;
}

export interface ClientMetricsMetadata {
    start: string;
    end: string;
    results: number;
}

export const clientMetricsApi = createApi({
    reducerPath: 'clientMetricsApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.backend.CLIENT_METRICS, prepareHeaders }),
    endpoints: (builder) => ({
        getMetricById: builder.query<ClientMetricsData[], { id: string }>({
            query: ({ id }) => ({
                url: `/token-requests/clientOrIntegrationId/${id}`,
                responseHandler,
            }),
        }),
        getMetadata: builder.query<ClientMetricsMetadata, void>({
            query: () => ({
                url: `/token-requests/metadata`,
                responseHandler,
            }),
        }),
    }),
});

export const { useGetMetricByIdQuery, useLazyGetMetricByIdQuery, useGetMetadataQuery, useLazyGetMetadataQuery } =
    clientMetricsApi;
