import { ChangeEvent, useState } from 'react';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { FormattedMessage } from 'react-intl';
import { FormInputField } from '../FormInputField';
import { AccountType } from '../../../../services/schemas/accountsBackendCodec';

interface AccountDeletionDialogProps {
    showDialog: boolean;
    setShowDialog: (value: boolean) => void;
    account: { name: string; id: string; type: string | undefined };
    handleDeleteConfirm: (accountId: string, reason: string) => void;
    deleteIsLoading: boolean;
}

export const AccountDeletionDialog = ({
    showDialog,
    setShowDialog,
    account,
    deleteIsLoading,
    handleDeleteConfirm,
}: AccountDeletionDialogProps) => {
    const [confirmationText, setConfirmationText] = useState('');
    // only required for the account types: 'BUYER', 'SUPPLIER'
    const [specialAccountTypeConfirmation, setSpecialAccountTypeConfirmation] = useState('');
    const [reason, setReason] = useState<string | undefined>(undefined);

    const specialCheckNecessary = account.type !== undefined && account.type !== AccountType.CARRIER;

    const resetState = () => {
        setConfirmationText('');
        setReason(undefined);
    };

    const handleReasonChange = (_propertyName: string, value: string) => setReason(value);
    const isReasonValid = () => reason && reason.trim().length > 0;
    const isConfirmationTextValid = () => 'DELETE' === confirmationText;
    const isSpecialConfirmationValid = () => {
        if (specialCheckNecessary) {
            return `LOGISTICS_${account.type}` === specialAccountTypeConfirmation;
        } else {
            return true;
        }
    };

    const isFormValid = () => {
        if (reason) {
            return isReasonValid() && isConfirmationTextValid() && isSpecialConfirmationValid();
        } else {
            return false;
        }
    };

    const handleConfirmationTextChange = (event: ChangeEvent<HTMLInputElement>) =>
        setConfirmationText(event.target.value);

    const handleSpecialConfirmationChange = (event: ChangeEvent<HTMLInputElement>) =>
        setSpecialAccountTypeConfirmation(event.target.value);

    const getModalContent = () => (
        <div data-cy={'modal-content-delete'}>
            <div>
                <FormInputField
                    dataAttribute="delete-account-dialog-reason-input"
                    translationId={'helpgang.addFleetAdminDialog.reason'}
                    value={reason}
                    propertyName={'reason'}
                    formError={reason?.length === 0 ? 'helpgang.deleteUserDialog.reason.error' : undefined}
                    required={true}
                    onValueChange={handleReasonChange}
                />
            </div>
            <p>
                <FormattedMessage id={'helpgang.modal.areYouSure'} />
            </p>
            <p className="text-size-20">{account.name}</p>
            {specialCheckNecessary && (
                <div>
                    <p>
                        <FormattedMessage id={'helpgang.modal.specialTypeConfirmation'} />:{' '}
                        <span className="text-bold text-color-danger pointer-events-none user-select-none">{`LOGISTICS_${account.type}`}</span>
                    </p>
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            value={specialAccountTypeConfirmation}
                            onChange={handleSpecialConfirmationChange}
                            data-cy={'delete-account-dialog-special-confirmation-input'}
                        />
                    </div>
                </div>
            )}
            <p>
                <FormattedMessage id={'helpgang.modal.typeConfirmation'} />:{' '}
                <span className="text-bold text-color-danger pointer-events-none user-select-none">DELETE</span>
            </p>
            <div className="form-group">
                <input
                    className="form-control"
                    type="text"
                    value={confirmationText}
                    onChange={handleConfirmationTextChange}
                    data-cy={'delete-account-dialog-confirmation-input'}
                />
            </div>
        </div>
    );

    const onClose = () => {
        resetState();
        setShowDialog(false);
    };

    const handleConfirmButtonClick = () => {
        reason && handleDeleteConfirm(account.id, reason);
        resetState();
    };

    return (
        <ConfirmationDialog
            show={showDialog}
            title={<FormattedMessage id={'helpgang.confirmationDialog.deleteAccount'} />}
            content={getModalContent()}
            bsSize={'sm'}
            onClickConfirm={handleConfirmButtonClick}
            onClickCancel={onClose}
            cancelButtonText={<FormattedMessage id={'helpgang.confirmationDialog.cancel'} />}
            confirmButtonText={<FormattedMessage id={'helpgang.confirmationDialog.deleteAccount'} />}
            useOverflow={true}
            disableConfirm={!isFormValid() && !deleteIsLoading}
        />
    );
};
