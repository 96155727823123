import { SelectOptionType } from '../dialogs/DropDownFormElement';

export const formOptions: SelectOptionType[] = [
    { id: 'BE', label: 'Belgium' },
    { id: 'BG', label: 'Bulgaria' },
    { id: 'DK', label: 'Denmark' },
    { id: 'DE', label: 'Germany' },
    { id: 'EE', label: 'Estonia' },
    { id: 'FI', label: 'Finland' },
    { id: 'FR', label: 'France' },
    { id: 'GR', label: 'Greece' },
    { id: 'IE', label: 'Ireland' },
    { id: 'IT', label: 'Italy' },
    { id: 'HR', label: 'Croatia' },
    { id: 'LV', label: 'Latvia' },
    { id: 'LT', label: 'Lithuania' },
    { id: 'LU', label: 'Luxembourg' },
    { id: 'MT', label: 'Malta' },
    { id: 'NL', label: 'Netherlands' },
    { id: 'AT', label: 'Austria' },
    { id: 'PL', label: 'Poland' },
    { id: 'PT', label: 'Portugal' },
    { id: 'RO', label: 'Romania' },
    { id: 'SE', label: 'Sweden' },
    { id: 'SK', label: 'Slovakia' },
    { id: 'SI', label: 'Slovenia' },
    { id: 'ES', label: 'Spain' },
    { id: 'CZ', label: 'Czech Republic' },
    { id: 'HU', label: 'Hungary' },
    { id: 'GB', label: 'United Kingdom' },
    { id: 'CY', label: 'Cyprus' },
    { id: 'NO', label: 'Norway' },
    { id: 'IS', label: 'Iceland' },
    { id: 'CH', label: 'Switzerland' },
    { id: 'LI', label: 'Liechtenstein' },
    { id: 'BR', label: 'Brazil' },
];

export const taxTypeOptions: SelectOptionType[] = [
    { id: 'VAT', label: 'VAT' },
    { id: 'CPF', label: 'CPF' },
    { id: 'CNPJ', label: 'CNPJ' },
];

export const getValidTaxTypeOptions = (tenant: string): SelectOptionType[] => {
    if (tenant.startsWith('rio-eu')) {
        return taxTypeOptions.filter((it) => it.id === 'VAT');
    } else if (tenant.startsWith('rio-brazil')) {
        return taxTypeOptions.filter((it) => it.id === 'CPF' || it.id === 'CNPJ');
    }
    return taxTypeOptions;
};

export const getLockedBecauseOptions = (selectedValue: string | undefined) =>
    selectedValue === undefined
        ? lockingOptions
        : lockingOptions.map(
              (item: SelectOptionType): SelectOptionType => ({
                  ...item,
                  selected: item.id === selectedValue,
              }),
          );
export const lockingOptions = [
    { id: 'SANCTION_LIST_HIT_UNDER_REVIEW', label: 'Sanction list hit under review' },
    {
        id: 'SANCTION_LIST_HIT_BLOCKED_AND_MARKED_FOR_DELETION',
        label: 'Sanction list hit blocked and marked for deletion',
    },
    { id: 'INSOLVENCY', label: 'Insolvency' },
    { id: 'OVERDUE_BILLS', label: 'Overdue bills' },
    { id: 'WAITING_FOR_CUSTOMER_FEEDBACK', label: 'Waiting for customer feedback' },
    { id: 'VAT_ID_INVALID', label: 'VAT ID invalid' },
    { id: 'FAKE_ACCOUNT', label: 'Fake account' },
    { id: 'OTHER', label: 'Other' },
];
