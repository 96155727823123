import { decodeZalandoRestError, ZalandoRestError } from '../../services/schemas/accountsBackendCodec';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { SerializedError } from '@reduxjs/toolkit';

export const ZalandoErrorNotificationContent = (props: {
    rtkError: FetchBaseQueryError | SerializedError | undefined;
}) => {
    const error = parseZalandoError(props.rtkError);

    // Detail is taken care of in Notification callback
    return (
        <>
            <p>{error.detail}</p>
            {error.field_errors && (
                <ul>
                    {Object.values(error.field_errors)?.map((fieldError, index) => <li key={index}>{fieldError}</li>)}
                </ul>
            )}
        </>
    );
};

export function parseZalandoError(updateError: FetchBaseQueryError | SerializedError | undefined): ZalandoRestError {
    const fallback = {
        title: 'Unknown error',
        status: 418,
        detail: 'Unknown error',
    };
    // this looks ugly but it seems to be the recommended way according to the rtk-query docs
    // https://redux-toolkit.js.org/rtk-query/usage-with-typescript#error-result-example
    if (updateError && 'status' in updateError && 'data' in updateError && typeof updateError.data === 'string') {
        try {
            return decodeZalandoRestError(JSON.parse(updateError.data));
        } catch (e) {
            console.warn('Problem when parsing error message:', e);
            return fallback;
        }
    } else {
        console.warn('Precondition on updateError for zalando error parsing not met:', updateError);
        return fallback;
    }
}
