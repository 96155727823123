import { CopyButton } from './CopyButton';
import { PropsWithChildren } from 'react';

interface Props {
    value: string;
    width?: number;
    fieldName?: string;
    tooltipText?: string;
}

export const ValueWithCopyButton = ({ children, value, width, fieldName, tooltipText }: PropsWithChildren<Props>) => (
    <div className={'row'}>
        <div className={width ? `col-${width}` : `col-10`}>
            <div className={'display-flex justify-content-around align-items-center'}>
                {children === undefined ? <span>{value}</span> : children}
                <CopyButton value={value} fieldName={fieldName} tooltipText={tooltipText} />
            </div>
        </div>
    </div>
);
