import { apis, SupportedApi } from './knownPartnerApis';
import { stringify } from 'yaml';
import { useState } from 'react';
import Select, { SelectOption } from '@rio-cloud/rio-uikit/Select';
import { Client } from '../../../../services/schemas/clientRegistryCodec';

const options: SelectOption[] = apis.filter((it) => !it.deprecated).map((it) => ({ id: it.id, label: it.name }));

export const ApiComparison = ({ client }: { client: Client | undefined }) => {
    const [apiIdToCompareWith, setApiIdToCompareWith] = useState<string>(SupportedApi.IOT_EVENTS);
    const updateSelectedItem = (item: SelectOption | undefined) => item && setApiIdToCompareWith(item.id);

    if (client === undefined) {
        return null;
    }

    return (
        <>
            <div className={'margin-left-25 col-xs-6 col-sm-4 form-group'}>
                <label htmlFor={'apiSelect'}>API to compare with:</label>
                <Select id={'apiSelect'} options={options} value={[apiIdToCompareWith]} onChange={updateSelectedItem} />
            </div>
            <div className={'margin-top-15 padding-left-20 padding-right-20'}>
                <table className={'table table-condensed'}>
                    <thead>
                        <tr>
                            <th className={'padding-left-5'}>Client Scopes & Permissions</th>
                            <th>Required Scopes & Permissions for API</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td width={'50%'}>
                                <pre>{stringify(client.scopes)}</pre>
                            </td>
                            <td>
                                <pre>{stringify(apis.find((it) => it.id === apiIdToCompareWith)?.scopes)}</pre>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <pre>{stringify(client.partnerIntegrationPermissionTemplates)}</pre>
                            </td>
                            <td>
                                <pre>{stringify(apis.find((it) => it.id === apiIdToCompareWith)?.permissions)}</pre>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};
