import { useIntl } from 'react-intl';
import { useState } from 'react';

import './CustomSearch.css';

interface Props {
    searchValue: any;
    setSearchValue: any;
    handleInputChange: any;
    handleKeyDown: any;
    isSearchDisabled?: boolean;
}

export const CustomSearch = (props: Props) => {
    const { searchValue, setSearchValue, handleInputChange, handleKeyDown, isSearchDisabled } = props;

    const intl = useIntl();
    const [buttonOverRemoveIcon, setButtonOverRemoveIcon] = useState<boolean | undefined>(undefined);

    return (
        <div className={'input-group input-group-lg'}>
            <span className={'input-group-addon'}>
                <span className={'rioglyph rioglyph-search text-size-20'} />
            </span>
            <input
                value={searchValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className={'form-control'}
                placeholder={intl.formatMessage({ id: 'helpgang.table.tableSearch' })}
                data-cy={'input-account-search'}
                readOnly={!!isSearchDisabled}
            />
            <span className={'input-group-addon'}>
                {isSearchDisabled ? (
                    <span className={'btn btn-muted btn-loading btn-icon-only'} />
                ) : (
                    <span
                        className={
                            'rioglyph rioglyph-remove-sign text-size-20 margin-right--5' +
                            (buttonOverRemoveIcon ? ' text-color-dark' : ' text-color-gray') +
                            (searchValue === '' ? ' remove-icon-hidden' : ' remove-icon-shown')
                        }
                        onClick={() => setSearchValue('')}
                        aria-hidden={'true'}
                        style={{ cursor: searchValue ? 'pointer' : '' }}
                        onMouseEnter={() => setButtonOverRemoveIcon(true)}
                        onMouseLeave={() => setButtonOverRemoveIcon(false)}
                    />
                )}
            </span>
        </div>
    );
};
