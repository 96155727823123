import Select, { SelectOption } from '@rio-cloud/rio-uikit/Select';

export const IdentifierSelectComponent = (props: IdentifierSelectComponentProps) => {
    const { identifierOptions, searchTypeValue, changeIdentifierSelection } = props;

    return (
        <Select
            bsSize={'lg'}
            options={identifierOptions}
            onChange={changeIdentifierSelection}
            value={[searchTypeValue]}
        />
    );
};

interface IdentifierSelectComponentProps {
    identifierOptions: SelectOption[];
    changeIdentifierSelection: (selectOption: SelectOption | undefined) => void;
    searchTypeValue: string;
}
