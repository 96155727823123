import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../config';
import { responseHandler, prepareHeaders } from './utils';
import {
    convertPartnerIntegrationQueryApiResponse,
    convertPartnerIntegrationByIdApiResponse,
    PartnerIntegration,
} from './schemas/partnerIntegrationCodec';
import { v4 as uuid } from 'uuid';

export const partnerIntegrationApi = createApi({
    reducerPath: 'partnerIntegrationApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.backend.PARTNER_INTEGRATION_SERVICE, prepareHeaders }),
    endpoints: (builder) => ({
        getById: builder.query<PartnerIntegration, { integrationId: string }>({
            query: (arg) => {
                const { integrationId } = arg;
                return {
                    url: `/${integrationId}`,
                    responseHandler,
                };
            },
            transformResponse: (rawResult: unknown) => convertPartnerIntegrationByIdApiResponse(rawResult),
        }),
        getByQueryParam: builder.query<
            PartnerIntegration[],
            { searchType: 'client_id' | 'account_id' | 'integration_id'; id: string }
        >({
            query: (arg) => {
                const { searchType, id } = arg;
                const urlParams =
                    searchType === 'integration_id'
                        ? {
                              url: `/${id}`,
                          }
                        : {
                              url: '',
                              params: { [searchType]: id },
                          };
                return {
                    ...urlParams,
                    responseHandler,
                };
            },
            transformResponse: (rawResult: unknown) => convertPartnerIntegrationQueryApiResponse(rawResult),
        }),
        createPartnerIntegration: builder.mutation<PartnerIntegration, { accountId: string; clientId: string }>({
            query: ({ accountId, clientId }) => {
                const integrationId = uuid();
                return {
                    url: `/${integrationId}`,
                    method: 'PUT',
                    body: { integration_id: integrationId, account_id: accountId, client_id: clientId },
                    responseHandler,
                };
            },
            transformResponse: (rawResult: unknown) => convertPartnerIntegrationByIdApiResponse(rawResult),
        }),
    }),
});

export const { useLazyGetByIdQuery, useLazyGetByQueryParamQuery, useCreatePartnerIntegrationMutation } =
    partnerIntegrationApi;
