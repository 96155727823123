import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Account } from '../../../../services/schemas/accountsBackendCodec';
import { RootState } from '../../../../../configuration/setup/store';

interface AccountSidebarState {
    show: boolean;
    selectedAccount?: Account;
}

const initialState: AccountSidebarState = {
    show: false,
    selectedAccount: undefined,
};

const accountSidebarSlice = createSlice({
    name: 'accountSidebar',
    initialState,
    reducers: {
        setShowAccountSidebar: (state, action: PayloadAction<boolean>) => {
            state.show = action.payload;
        },
        accountSelected: (state, action: PayloadAction<Account | undefined>) => {
            state.selectedAccount = action.payload;
        },
        resetAccountsSidebar: (state) => {
            state.show = false;
            state.selectedAccount = undefined;
        },
    },
});

export const getShowAccountSidebar = (state: RootState) => state.accountSidebar.show;
export const getSidebarAccount = (state: RootState) => state.accountSidebar.selectedAccount;

export const accountSidebarReducer = accountSidebarSlice.reducer;
export const { setShowAccountSidebar, accountSelected, resetAccountsSidebar } = accountSidebarSlice.actions;
