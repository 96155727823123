import * as t from 'io-ts';
import { pipe } from 'fp-ts/function';
import { decode } from './iotsDecoder';

const GrantTypeC = t.keyof({
    authorization_code: null,
    client_credentials: null,
    partner_integration: null,
    implicit: null,
    refresh_token: null,
    password: null,
});

const PartnerIntegrationPermissionTemplateC = t.type({ service: t.string, permissions: t.array(t.string) });
export type PartnerIntegrationPermissionTemplate = t.TypeOf<typeof PartnerIntegrationPermissionTemplateC>;

const ExternalClientC = t.type({
    client_id: t.string,
    name: t.string,
    status: t.string,
    scopes: t.array(t.string),
    grant_types: t.array(GrantTypeC),
    email: t.string,
    description: t.string,
    detailed_description: t.string,
    created_at: t.string,
    modified_at: t.string,
    trusted: t.boolean,
    callback_urls: t.array(t.string),
    client_credentials_permissions: t.array(t.unknown),
    partner_integration_permission_templates: t.array(PartnerIntegrationPermissionTemplateC),
});

export type ExternalClient = t.TypeOf<typeof ExternalClientC>;

const ClientC = t.type({
    id: t.string,
    name: t.string,
    status: t.string,
    scopes: t.array(t.string),
    grantTypes: t.array(GrantTypeC),
    email: t.string,
    description: t.string,
    detailedDescription: t.string,
    createdAt: t.string,
    modifiedAt: t.string,
    trusted: t.boolean,
    callbackUrls: t.array(t.string),
    clientCredentialsPermissions: t.array(t.unknown),
    partnerIntegrationPermissionTemplates: t.array(PartnerIntegrationPermissionTemplateC),
});

export type Client = t.TypeOf<typeof ClientC>;

const ClientRegistryApiResponseC = t.array(ExternalClientC);
type ClientRegistryApiResponse = t.TypeOf<typeof ClientRegistryApiResponseC>;

const decodeExternalClient = (input: unknown): ClientRegistryApiResponse => decode(input, ClientRegistryApiResponseC);

export const convertExternalToInternalClient = (input: ExternalClient): Client => ({
    id: input.client_id,
    name: input.name,
    status: input.status,
    scopes: input.scopes,
    grantTypes: input.grant_types,
    email: input.email,
    description: input.description,
    detailedDescription: input.detailed_description,
    createdAt: input.created_at,
    modifiedAt: input.modified_at,
    trusted: input.trusted,
    callbackUrls: input.callback_urls,
    clientCredentialsPermissions: input.client_credentials_permissions,
    partnerIntegrationPermissionTemplates: input.partner_integration_permission_templates,
});

export const decodeClientRegistryResponse = (input: unknown): Client[] =>
    pipe(input, decodeExternalClient, (it) => it.map(convertExternalToInternalClient));
