import { config } from '../../../../config';

export const InboundConnectionsIframe = () => {
    return (
        <iframe
            data-testid="eocs-inbound-connections"
            src={`${config.backend.INBOUND_CONNECTIONS_URL}`}
            className={'unstyled full-window'}
        />
    );
};
