import classNames from 'classnames';
import { JSX, useState } from 'react';

import LoadMoreButton from '@rio-cloud/rio-uikit/LoadMoreButton';
import SortDirection from '@rio-cloud/rio-uikit/SortDirection';
import SortArrows from '@rio-cloud/rio-uikit/SortArrows';
import { sortByProperty, type SortDirectionType } from '@rio-cloud/rio-uikit/SortUtils';
import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';
import { FormattedDateTime } from '../../../common/FormattedDateTime';
import { PartnerIntegration } from '../../../../services/schemas/partnerIntegrationCodec';
import { useGetMetadataQuery } from '../../../../services/metricsApi';
import { ClientIdWithName } from '../../../common/ClientIdWithName';
import { ValueWithCopyButton } from '../../../common/ValueWithCopyButton';
import { AccountIdWithName } from '../../../common/AccountIdWithName';
import { PartnerIntegrationStatus } from '../../../common/PartnerIntegrationStatus';

const DEFAULT_COLUMN_ORDER = ['integrationId', 'clientId', 'accountId', 'tokenStatus'] as const;
type Column = (typeof DEFAULT_COLUMN_ORDER)[number];

const columnLabels: { [key: string]: string } = {
    integrationId: 'Integration Id',
    clientId: 'Client Id',
    accountId: 'Account Id',
    tokenStatus: 'Token Response',
};

const tableClassNames = classNames(
    'table',
    'table-layout-fixed',
    'table-column-overflow-hidden',
    'table-bordered',
    'table-sticky',
    'table-head-filled',
);

const INITIAL_DISPLAY_COUNT = import.meta.env.PROD ? 25 : 3;

const getSortDir = (sortDir: string, sortBy: Column, previousSortBy: Column): SortDirectionType => {
    if (sortBy === previousSortBy) {
        return sortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
    }
    return SortDirection.ASCENDING;
};

const getTooltip = (value: string | undefined) =>
    value ? (
        <div>
            <div>Last time the token response data was updated: </div>
            <FormattedDateTime value={value} />
        </div>
    ) : undefined;

export const PartnerIntegrationTable = ({
    tableItems,
}: {
    tableItems: (PartnerIntegration & { tokenStatus?: JSX.Element })[];
}) => {
    const [displayedItems, setDisplayedItems] = useState(INITIAL_DISPLAY_COUNT);

    const [sortBy, setSortBy] = useState<Column>('accountId');
    const [sortDir, setSortDir] = useState<SortDirectionType>(SortDirection.ASCENDING);
    const [columnOrder] = useState(DEFAULT_COLUMN_ORDER);

    const [hiddenColumns] = useState<string[]>([]);

    const { data: clientMetricsMetadata } = useGetMetadataQuery();

    const loadMore = () =>
        setDisplayedItems((prev) => {
            const stepSize = 25;
            const availableItems = tableItems.length;
            if (prev < availableItems) {
                const sum = prev + stepSize;
                return sum > availableItems ? availableItems : sum;
            } else {
                return availableItems;
            }
        });

    // filter for hidden columns
    const columns = columnOrder.filter((name) => !hiddenColumns.includes(name));

    const handleSortChange = (event: any) => {
        const newSortBy = event.currentTarget.getAttribute('data-sortby');
        handleCardSortChange(newSortBy, getSortDir(sortDir, newSortBy, sortBy));
    };

    const handleCardSortChange = (newSortBy: Column, newSortDir: SortDirectionType) => {
        setSortBy(newSortBy);
        setSortDir(newSortDir);
    };

    // const sortedItems = tableItems.
    const renderTableHead = (column: Column, label: string, sortBy: string, sortDir: string) => {
        const sortColumns: Column[] = ['accountId'];
        const isSortColum = sortColumns.includes(column as any);
        const isStatusColumn = column === 'tokenStatus';

        const tableHeadClassNames = isSortColum ? classNames('user-select-none', 'sort-column') : '';

        return (
            <th
                key={column}
                className={tableHeadClassNames}
                onClick={isSortColum ? handleSortChange : () => {}}
                data-field={column}
                data-sortby={column}
                title={label}
                style={{ width: isStatusColumn ? 150 : undefined }}
            >
                <span>
                    {isSortColum ? sortBy === column ? <SortArrows direction={sortDir} /> : <SortArrows /> : null}
                    {column === 'tokenStatus' ? (
                        <SimpleTooltip placement={'auto'} content={getTooltip(clientMetricsMetadata?.end)}>
                            <div>
                                <span style={{ cursor: 'default' }}>{label}</span>
                                <span className={'margin-left-5 rioglyph rioglyph-info-sign text-color-dark'} />
                            </div>
                        </SimpleTooltip>
                    ) : (
                        <span>{label}</span>
                    )}
                </span>
            </th>
        );
    };

    const rows = sortBy && tableItems ? sortByProperty(tableItems, sortBy, sortDir) : tableItems;

    const loadTokenStatus = rows.length <= 5;

    return (
        <>
            <table className={tableClassNames}>
                <thead>
                    <tr>{columns.map((column) => renderTableHead(column, columnLabels[column], sortBy, sortDir))}</tr>
                </thead>
                <tbody>
                    {rows.slice(0, displayedItems).map((it, idx) => (
                        <tr key={idx} data-cy={'partner-integrations-table-row'}>
                            <td>
                                <ValueWithCopyButton value={it.integrationId} width={8} />
                            </td>
                            <td>
                                <ValueWithCopyButton value={it.clientId} width={8}>
                                    <ClientIdWithName clientId={it.clientId} />
                                </ValueWithCopyButton>
                            </td>
                            <td>
                                <ValueWithCopyButton value={it.accountId} width={8}>
                                    <AccountIdWithName accountId={it.accountId} />
                                </ValueWithCopyButton>
                            </td>
                            <td>
                                <PartnerIntegrationStatus id={it.integrationId} autoLoad={loadTokenStatus} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {displayedItems <= tableItems.length && (
                <LoadMoreButton
                    loaded={displayedItems}
                    total={tableItems.length}
                    onLoadMore={loadMore}
                    loadMoreMessage={'Load more'}
                    noMoreMessage={'Everything loaded'}
                />
            )}
        </>
    );
};
